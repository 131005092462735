import * as React from 'react';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import PasswordInput from '../../app/common/form/PasswordInput';
import TextInput from '../../app/common/form/TextInput';
import { useStore } from '../../app/stores/store';
import * as Yup from 'yup';

const LoginForm = () => {
    const {  authStore } = useStore();

    return (
        <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={(values, { setErrors }) => authStore.login(values).catch(e => setErrors({ password: 'Invalid password', username: 'Invalid username' }))}
            validationSchema={Yup.object({
                username: Yup.string().required('Username field is required.').email('Invalid username.'),
                password: Yup.string().required('Password field is required.')
            })}
        >
            {
                ({ handleSubmit, isSubmitting, errors }) => (

                    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                        <Grid.Column style={{
                            width: '436px',
                            position: 'relative',
                            top:'-20px',
                            padding: '54px 32px',
                            boxShadow: '0px 3px 6px rgb(21 146 255 / 16%)',
                            borderRadius: '16px',
                            backgroundColor:'#ffffff'
                        }}>
                            <div style={{ textAlign: 'center', marginBottom:'32px' }}>
                                <Image src={`assets/kasina-logo.svg`} style={{ width: '135px', marginBottom: '12px' }} centered />
                                <Header as='h6' style={{letterSpacing:'5.6px',fontSize:'8px', marginTop:0, marginLeft:'10px'}}>CLIENT PORTAL</Header>
                            </div>
                            <Header as='h3' style={{ marginBottom: '32px',textAlign:'left'  }}>Login</Header>
                            <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                <TextInput name='username' placeholder='Username' label='Username' />
                                <PasswordInput name='password' placeholder='Password' label='Password' />
                                <Grid columns='equal' style={{marginTop:'30px', alignItems: 'center'}}>
                                    <Grid.Column>
                                        <Link to={`/ForgotPassword`}>
                                            Forgot Password?
                                        </Link >
                                    </Grid.Column>
                                    <Grid.Column width={5}>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Button loading={isSubmitting} color='blue' style={{ backgroundColor: '#1592ff', borderRadius: '8px' }} fluid content='Login' type='submit' size='large' />
                                    </Grid.Column>
                                </Grid>
                            </Form>
                        </Grid.Column>
                    </Grid>
                )
            }
        </Formik>
    );
}

export default observer(LoginForm);