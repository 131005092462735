import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import DateHelper from '../../app/helpers/dateHelper';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { useStore } from '../../app/stores/store';

// #region Styles
const StyledTextArea = styled(Form.TextArea)`
        textarea{
            resize:none !important;
        }
`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

const StyledCardContainer = styled.div`
        padding: 2em;
        background-color: #fff;
        border-radius: 16px;
`;
// #endregion

const dateHelper = new DateHelper();

const OvertimeRequestDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { overtimeStore } = useStore();
    const { overtime, loadOvertime, isOvertimeDetailsLoading } = overtimeStore;

    useEffect(() => {
        loadOvertime(id);
    }, [loadOvertime, id]);

    if (isOvertimeDetailsLoading) return <LoadingComponent content='Loading Overtime Details...' />

    return (
        <StyledContainer>
        <StyledCardContainer>
            <Grid divided='vertically'>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header >Overtime Details</Header>
                    </Grid.Column>
                    <Grid.Column width={10} style={{ marginTop: '2em' }}>
                        <Form>
                            <Form.Group>
                                <Form.Input value={overtime?.user.name} readOnly name='employee' label='Employee' width={16} />
                            </Form.Group>
                                <Form.Group>
                                    <Form.Input type='text' value={overtime?.from != undefined ? dateHelper.formatLocalDateTime(overtime?.from.toString()) : ""} readOnly name='from' label='From' width={8} />
                                    <Form.Input type='text' value={overtime?.to != undefined ? dateHelper.formatLocalDateTime(overtime?.to.toString()) : ""} readOnly name='to' label='To' width={8} />
                            </Form.Group>
                            <Form.Group>
                                <StyledTextArea rows={3} value={overtime?.reason} readOnly name='reason' label='Reason' width={16} />
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={3}>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Button as={Link} basic color='grey' floated='right' to='/requests/overtime-requests'>Cancel</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </StyledCardContainer>
        </StyledContainer>
    );
}

export default observer(OvertimeRequestDetails);