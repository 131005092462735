import { Button, IconButton, InputBase, makeStyles, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { MdClear } from 'react-icons/md';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { CardGroup } from '../../../app/models/cardGroup';
import { useStore } from '../../../app/stores/store';

const StylePaperContainer = styled(Paper)`
    && {
        width: 272px;
        background-color: #ebecf0;
        margin: 8px 0 0 8px;
        height: min-content;
        max-height: 100%;
        padding-top: 8px;
    }
`;

const useStyle = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1, 1, 1, 2),
        margin: theme.spacing(1, 1, 1, 1),
    },
    input: {
        fontSize: '.9rem'
    },
    btnConfirm: {
        background: '#0079bf',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#026aa7'
        }
    },
    confirm: {
        margin: theme.spacing(0, 1, 1, 1),
    }
}));

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    boardId: string;
    cardGroup?: CardGroup;
};

const InputList = ({ setOpen, boardId, cardGroup }: Props) => {
    const classes = useStyle();
    const { cardGroupStore } = useStore();
    const { add } = cardGroupStore;

    const [title, setTitle] = useState<string>('');

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const handleBtnConfirm = () => {
        if (title.length > 0 && title.length <= 512) {
            add({ title: title, boardId: boardId });
        }
        else
        {
            toast.error('List title should not be empty or exceed 512 characters.');
        }
        setTitle('');
        setOpen(false);
    }

    const handleClear = () => {
        setTitle('');
        setOpen(false);
    }

    return (
        <StylePaperContainer elevation={0}>
            <div>
                <Paper className={classes.card}>
                    <InputBase
                        multiline
                        fullWidth
                        inputProps={{ className: classes.input }}
                        placeholder='Enter list title...'
                        value={title}
                        onChange={handleOnChange}
                    />
                </Paper>
            </div>
            <div className={classes.confirm}>
                <Button className={classes.btnConfirm} onMouseDown={handleBtnConfirm}>
                    Add List
                </Button>
                <IconButton onClick={handleClear}>
                    <MdClear />
                </IconButton>
            </div>
        </StylePaperContainer>
    );
}

export default InputList;