import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agents';
import DateHelper from '../helpers/dateHelper';
import { FilterValues } from '../models/common/filterValues';
import { Department, Education, Employee, EmployeeFilterValues, Schedule, WorkExperience } from '../models/employee';
import { DailyReport } from '../models/employeeDailyReport';
import { history } from '../../index';
import { toast } from 'react-toastify';
import { TimeRecord } from '../models/timeRecord';
import { OptionSelect } from '../models/option';

const _dateHelper = new DateHelper();

export default class EmployeeStore {
    employees: Employee[] = [];
    employeesDepartment: Employee[] = [];
    departments: Department[] = [];
    total: number = 0;
    loadingInitial = false;
    isLoading = false;
    filterValues: EmployeeFilterValues = {
        pageNumber: 1, pageSize: 10, searchKeyword: "",
        departments: []
    };

    //EmployeeDetails
    employee: Employee | undefined;
    workExperiences: WorkExperience[] | undefined;
    educationRecords: Education[] | undefined;
    schedule: Schedule | undefined;
    isEmployeeLoaded = false;

    searchingEmployee = false;
    searchedEmployees: Employee[] = [];

    dailyReports: DailyReport[] | undefined;
    dailyReportsTotal: number = 0;
    isDailyReportLoaded = false;

    timeRecords: TimeRecord[] | undefined;
    timeRecordTotal: number = 0;
    isTimeRecordsLoaded = false;

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber ? this.filterValues.pageNumber - 1 : 0;
    }

    get pivotSchedule() {
        if (this.schedule) {
            return [{
                day: 'Monday',
                startTime: this.schedule.mondayWorkingDay ? _dateHelper.getLocalTime(this.schedule.mondayStart) : '--',
                endTime: this.schedule.mondayWorkingDay ? _dateHelper.getLocalTime(this.schedule.mondayEnd) : '--'
            }, {
                day: 'Tuesday',
                startTime: this.schedule.tuesdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.tuesdayStart) : '--',
                endTime: this.schedule.tuesdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.tuesdayEnd) : '--'
            }, {
                day: 'Wednesday',
                startTime: this.schedule.wednesdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.wednesdayStart) : '--',
                endTime: this.schedule.wednesdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.wednesdayEnd) : '--'
            }, {
                day: 'Thursday',
                startTime: this.schedule.thursdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.thursdayStart) : '--',
                endTime: this.schedule.thursdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.thursdayEnd) : '--'
            }, {
                day: 'Friday',
                startTime: this.schedule.fridayWorkingDay ? _dateHelper.getLocalTime(this.schedule.fridayStart) : '--',
                endTime: this.schedule.fridayWorkingDay ? _dateHelper.getLocalTime(this.schedule.fridayEnd) : '--'
            }, {
                day: 'Saturday',
                startTime: this.schedule.saturdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.saturdayStart) : '--',
                endTime: this.schedule.saturdayWorkingDay ? _dateHelper.getLocalTime(this.schedule.saturdayEnd) : '--'
            }, {
                day: 'Sunday',
                startTime: this.schedule.sundayWorkingDay ? _dateHelper.getLocalTime(this.schedule.sundayStart) : '--',
                endTime: this.schedule.sundayWorkingDay ? _dateHelper.getLocalTime(this.schedule.sundayEnd) : '--'
            }];
        }

    }

    loadEmployees = async (filters: FilterValues) => {
       
        this.setLoading(true);
        try {
            const { total, employees } = await agent.Employees.filter(filters);
        
            runInAction(() => {
                this.employees = employees;
                this.employeesDepartment = employees;
            
                this.total = total;
            });
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoading(false);
        }
    }
    loadAllDepartment = async () => {
       
        this.setLoading(true);
        try {
            const { total, department } = await agent.Employees.getAllDepartment();
        
            runInAction(() => {
               this.departments = department;
            });
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoading(false);
        }
    }


    loadEmployeesListByDepartment = async (filters: EmployeeFilterValues) => {
        this.setLoading(true);
        try {
            const { total, employees } = await agent.Employees.filterByDepartment(filters);
            runInAction(() => {
                this.employees = employees;
                this.total = total;
            });
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoading(false);
        }
    }


    get userOptionValues(): OptionSelect[] {
        if (this.departments.length) {
            let sortedByName = this.departments.slice(0);
            let unique:any = []
            for (let char of sortedByName) {
                let check = unique.find(e => JSON.stringify(e) == JSON.stringify(char))
                if (!check) {
                    unique.push(char)
                }
            }
            return unique.map(e => {
                return { value: e.department.id, label: e.department.name } as OptionSelect
            });
        } else {
            return [];
        }
    }

    loadEmployee = async (userId: string) => {
        this.isEmployeeLoaded = false;
        try {
            const employeePersonalInfo = await agent.Employees.loadPersonalDetails(userId);
            const workExperiences = await agent.Employees.loadWorkExperiences(userId);
            const educationRecords = await agent.Employees.loadEducationRecords(userId);
            const schedule = await agent.Employees.loadSchedule(userId);
            runInAction(() => {
                this.employee = employeePersonalInfo;
                this.workExperiences = workExperiences;
                this.educationRecords = educationRecords;
                this.schedule = schedule;
                this.isEmployeeLoaded = true;
            });
        } catch (e) {
            console.log(e);
            this.isEmployeeLoaded = false;
            toast.error('Cannot load employee.');
            history.push('/employees');
        }
    }

    searchEmployee = async (search: string) => {
        this.searchingEmployee = true;

        try {
            const { total, employees } = await agent.Employees.filter({
                searchKeyword: search,
                pageSize: 0,
                pageNumber: 0
            });
            runInAction(() => {
                employees.map(e => {
                    if (!this.searchedEmployees.find(s => s.id == e.id)) {
                        this.searchedEmployees.push(e);
                    }
                });
                this.searchingEmployee = false;
            });
        } catch (error) {
            this.searchingEmployee = false;
        }
    }

    loadEmployeeDailyReports = async (filterValues: FilterValues) => {
        this.isDailyReportLoaded = false;
        try {
            const { total, data } = await agent.Employees.filterDailyReports({
                id: filterValues.id,
                pageNumber: filterValues.pageNumber,
                pageSize: filterValues.pageSize,
                searchKeyword: filterValues.searchKeyword
            });
            runInAction(() => {
                this.dailyReports = data;
                this.dailyReportsTotal = total;
                this.isDailyReportLoaded = true;
            });

        } catch (error) {
            console.error(error);
            this.isDailyReportLoaded = true;
        }
    }

    loadEmployeeDTR = async (filterValues: FilterValues) => {
        this.isTimeRecordsLoaded = false;
        try {
            const { total, data } = await agent.Employees.filterDTR(filterValues);
            runInAction(() => {
                this.timeRecords = data;
                this.timeRecordTotal = total;
                this.isTimeRecordsLoaded = true;
            });

        } catch (error) {
            console.error(error);
            this.isTimeRecordsLoaded = true;
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    reset = () => {
        this.searchingEmployee = false;
        this.searchedEmployees = [];
    }

}