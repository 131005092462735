import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Icon, Label } from "semantic-ui-react";
import styled from 'styled-components';
import * as Yup from 'yup';
import { useStore } from "../../stores/store";
import FormTextInput from "../form/FormTextInput";
import ReceiverMultiSelect from "./ReceiverMultiSelect";

// #region Styling

const NewGroupMessageContainer = styled.div<{ isOpen: boolean }>`
    && {
        display: ${p => p.isOpen ? 'block' : 'none'};
        position: fixed;
        right:15px;
        bottom: 0px;
        background-color: #fff;
        width: 350px;
        height: 450px;
        box-shadow: 0 0 5px #ccc;
        border-radius: 5px 5px 0 0;
    }
`;

const StyledLabel = styled(Label)`
    && {
        background-color: #f6fbff;
        height: 43px;
        font-size: 14px;
        padding: .6em .9em;
        bottom-margin: solid 1px #d1dbe3;
        font-family: Helvetica Neue,Segoe UI,Helvetica,Arial,sans-serif;
    }
`;

const StyledButton = styled(Button)`
    && {
        
        color: #fff  !important;
        width: 100%;
        position: absolute  !important;
        bottom: 0;
        height: 42px;
        border-radius: 0 !important;
    }

`;

const selectStyles = {
    container: (provided, state) => ({
        ...provided,
        margin: '5px',
        width: '97%'
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '100px'
    }),
    menuList: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '190px'
    }),
}
// #endregion

interface NewGroupChatSchema {
    groupName: string ,
    userIds: { value: string }[]
}


const NewGroupMessage = () => {
    const { chatStore, employeeStore, messageStore, conversationStore } = useStore();
    const { newGroupMessageOpen, setGroupNewMessageOpen,
        hideChatBox, showMessageBox, connectionId,
        isContactsLoading, contactsOptionValues, loadContacts } = chatStore;
    const { isMessageSending, loadMessage } = messageStore;
    const { postConversation, getCoversationById, setSelectedConversation } = conversationStore;
    const [isLoading, setIsLoading] = useState(false);

    const formikRef = useRef<FormikProps<NewGroupChatSchema>>(null);

    const handleSubmitNewConversation = (values: NewGroupChatSchema, formikHelpers: FormikHelpers<NewGroupChatSchema>) => {
        setIsLoading(true);
        postConversation({
            conversationName: values.groupName,
            receiversId: values.userIds.map(u => u.value),
            connectionId: connectionId
        })
            .then((conversationId) => {
                getCoversationById(conversationId!).then(conversation => {
                    if (conversation) {
                        setSelectedConversation(conversation);
                        loadMessage(conversation?.id!);
                        setGroupNewMessageOpen(false);
                        hideChatBox();
                        showMessageBox();
                    }
                    setIsLoading(false);
                    formikHelpers.setSubmitting(false);
                    formikHelpers.resetForm();
                });
            }, (reason) => {
                toast.error(reason);
                setIsLoading(false);
                formikHelpers.setSubmitting(false);
                formikHelpers.resetForm();
            });
    }

    const onNewMessageClose = () => {
        setGroupNewMessageOpen(false);
    }

    const validationSchema = Yup.object({
        groupName: Yup.string()
            .required('Group Name field is required.')
            .trim('Group Name field is required.')
            .min(1, 'Group Name field is required.')
            .max(128, 'Group name should not exceed 128 characters.'),
        userIds: Yup.array().required().min(1, 'Select a receiver.')
    });

    useEffect(() => {
        loadContacts();

        return () => {
            employeeStore.reset();
            if (formikRef.current) {
                formikRef.current.setErrors({});
                formikRef.current.resetForm();
            }
        }
    }, [loadContacts, chatStore]);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                groupName: '',
                userIds: []
            } as NewGroupChatSchema}
            onSubmit={handleSubmitNewConversation}
            validationSchema={validationSchema}
            innerRef={formikRef}
        >
            {
                ({ handleSubmit, isSubmitting, isValid, setErrors, resetForm, dirty }) => {
                    return (
                        <NewGroupMessageContainer
                            isOpen={newGroupMessageOpen}>
                            <StyledLabel attached='top'>
                                New Group Chat
                                <Icon
                                    onClick={() => {
                                        setErrors({});
                                        resetForm();
                                        onNewMessageClose();
                                    }}
                                    style={{ float: "right" }}
                                    size='small'
                                    name='delete' />
                            </StyledLabel>
                            <Form style={{ paddingTop: 16, height: '100%' }} onSubmit={handleSubmit} autoComplete='off'>
                                <FormTextInput
                                    name='groupName'
                                    placeholder='Group Name...'
                                    style={{
                                        padding: '5px',
                                        width: '100%',
                                    }}
                                />
                                <ReceiverMultiSelect
                                    styles={selectStyles}
                                    name='userIds'
                                    options={contactsOptionValues}
                                    isLoading={isContactsLoading}
                                    isMulti={true}
                                    placeholder='Select Receiver...'
                                    noOptionsMessage={() => 'No user available'}
                                />
                                <StyledButton
                                    type='submit'
                                    color='blue'
                                    content='Done'
                                    labelPosition='right'
                                    icon='send'
                                    loading={isSubmitting || isLoading}
                                    disabled={!dirty || !isValid || isSubmitting || isLoading}
                                />
                            </Form>
                        </NewGroupMessageContainer>
                    )
                }
            }
        </Formik>
    )
}

export default observer(NewGroupMessage);