import { InputBase, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Header, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import { Card, CardFormValues } from '../../../app/models/card';
import { useStore } from '../../../app/stores/store';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Board } from '../../../app/models/board';
import { observer } from 'mobx-react-lite';

//#region Styling
const TitleContainer = styled.div`
     && {
        display: flex;
        align-items: center;
     }
`;

const EditableTitle = styled(Header)`
        && {
        font-size: 16px;
        font-weight: 600;
        color: #172b4d;
        padding: 4px 8px;
        word-wrap: break-word;
        overflow: hidden auto;
        min-height:20px;
        max-height:256px;
        line-height: normal;
    }
`;

const TitleWrapper = styled.div`
        && {
           height: auto;
            flex-grow: 1;
            overflow: auto;
            width:240px;
        }
`;

const StyledTextArea = styled(TextareaAutosize)`
    && {
        width:100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background: #0000;
        border-radius: 3px;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        min-height: 24px;
        padding: 4px 8px;
        resize: none;
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #0079bf;
        border: none;
        outline:none;
}
`;
//#endregion

interface Props {
    card: Card
}

const TitleInput = ({ card }: Props) => {
    const minRows = 1;
    const [open, setOpen] = useState(false);
    
    const [title, setTitle] = useState(card.title);
    const { cardStore, boardStore } = useStore();

    const { update } = cardStore;

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.target.value);
    }

    const handleBlur = () => {
        let trimmedTitle = title.trim();
        if (trimmedTitle.length < 1) {
            setTitle(card.title);
            setOpen(false);
            toast.error('Task title should not be empty');
        }
        else if (trimmedTitle.length >= 512) {
            setTitle(card.title);
            toast.error('Task title should not exceed 512 characters');
        } else {
            card.title = trimmedTitle;
            update(new CardFormValues(card));
            setOpen(false);
        }
    }

    useEffect(() => {
        setTitle(card.title);
    }, [card.title])

    return (
        <TitleContainer>
            {
                open ? (
                    <StyledTextArea
                        value={title}
                        onBlur={handleBlur}
                        onChange={handleOnChange}
                        minRows={minRows}
                        autoFocus
                    />
                ) : (
                    <TitleWrapper>
                        <EditableTitle onClick={() => setOpen(!open)}>
                            {title}
                        </EditableTitle>
                    </TitleWrapper>
                )}
        </TitleContainer>
    );
}

export default observer(TitleInput);