import { Collapse, fade, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import InputList from './InputList';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '280px',
    },
    addCard: {
        padding: theme.spacing(1, 1, 1, 2),
        margin: theme.spacing(1, 1, 1, 1),
        backgroundColor: '#ebecf0',
        width: '272px',
        '&:hover': {
            backgroundColor: fade('#fff', 0.75),
            cursor: 'pointer'
        }
    }
}));

interface Props {
    boardId: string;
}

const InputListContainer = ({ boardId }: Props) => {
    const classes = useStyle();
    const [open, setOpen] = useState(false);

    return (
        <div className={classes.root}>
            <Collapse in={open} timeout={85}>
                <InputList setOpen={setOpen} boardId={boardId!} />
            </Collapse>
            <Collapse in={!open} timeout={85}>
                <Paper className={classes.addCard} elevation={0} onClick={() => setOpen(!open)}>
                    <Typography>
                        + Add another List
                    </Typography>
                </Paper>
            </Collapse>

        </div>
    );
}

export default InputListContainer;