import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import { Button, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CardChecklistItem, CardChecklistItemFormValues } from '../../../app/models/cardChecklist';
import { useStore } from '../../../app/stores/store';

//#region Styling
const ComponentWrapper = styled.div`
     && {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        margin: 0 0 8px -8px !important;
        border-radius: 6px;
     }
    &:hover{
        background-color: #091e420a !important;
    }
`;

const LabelContainer = styled.div`
     && {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin:8px !important;
     }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
`;

const StyledTextArea = styled(TextareaAutosize) <{ isCompleted: boolean }>`
    && {
        width:100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background: #0000;
        border-radius: 3px;
        line-height: 24px;
        min-height: 24px;
        padding: 4px 8px;
        resize: none;
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #0079bf;
        border: none;
        outline:none;
        display:block;
       text-decoration: ${p => (p.isCompleted ? 'line-through' : 'none')};
    }
`;

const StyledText = styled.p<{ isCompleted: boolean }>`
    && {
        width:100%;
        white-space: pre-wrap;
        flex-grow:1;
        margin:0;
        text-decoration: ${p => (p.isCompleted ? 'line-through' : 'none')};
        overflow-wrap: break-word;
        word-break: break-word;
    }
`;

const DisplayContainer = styled.div`
    && {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        width:100%;
    }
`;

const DeleteIcon = styled(BsTrash)`
        &&{
        font-size: 20px;
        border-radius: 4px;
        width: 25px;
        height: 25px;
        align-self: flex-start;
        padding: 4px;
        color:#717171;
        cursor: pointer;
}
`;

//#endregion

interface Props {
    checklistItem: CardChecklistItem;
}

const _swal = withReactContent(Swal);

const ChecklistItem = ({ checklistItem }: Props) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(checklistItem.title);
    const [completed, setCompleted] = useState(checklistItem.isCompleted);
    const { cardChecklistStore } = useStore();
    const { updateItem, deleteItem } = cardChecklistStore;

    useEffect(() => {
        setTitle(checklistItem.title);
    }, [checklistItem]);

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.target.value);
    }

    const handleOnSave = async (completeProps?: boolean) => {
        if (title && title.length > 0 && title.length <= 512) {
            checklistItem.title = title;
            checklistItem.isCompleted = typeof completeProps === 'boolean' ? completeProps! : completed;
            updateItem(new CardChecklistItemFormValues(checklistItem));
            setOpen(false);
        } else {
            setTitle(checklistItem.title);
            toast.error('Item should not be empty or exceed 512 characters');
            setOpen(false);
        }
    }
    const handleOnChecked = async (completeProps?: boolean) => {
        if (title && title.length > 0 && title.length <= 512) {
            checklistItem.isCompleted = typeof completeProps === 'boolean' ? completeProps! : completed;
            updateItem(new CardChecklistItemFormValues(checklistItem));
        } else {
            setTitle(checklistItem.title);
            toast.error('Item should not be empty or exceed 512 characters');
        }
    }

    const handleClear = () => {
        setTitle(checklistItem.title);
        setOpen(false);
    }

    const toggleCompleted = () => {
        let newCompleteState = !completed;
        setCompleted(newCompleteState);
        handleOnChecked(newCompleteState);
    }

    const handleDelete = () => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Checklist item?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItem(checklistItem.id);
            }
        });
    }

    return (
        <ComponentWrapper>
            <Checkbox
                style={{ margin: '8px' }}
                checked={checklistItem.isCompleted}
                onClick={toggleCompleted}
            />
            <LabelContainer>
                {open ? (
                    <>
                        <StyledTextArea
                            autoFocus
                            value={title}
                            onChange={handleOnChange}
                            minRows={2}
                            placeholder='Add an item'
                            isCompleted={checklistItem.isCompleted}
                        />
                        <ButtonContainer>
                            <Button
                                compact
                                color='blue'
                                size='small'
                                onMouseDown={handleOnSave}>
                                Save
                            </Button>
                            <IconButton onClick={handleClear}>
                                <MdClear />
                            </IconButton>
                        </ButtonContainer>
                    </>
                ) : (
                    <DisplayContainer>
                        <StyledText
                            isCompleted={checklistItem.isCompleted}
                            onClick={() => setOpen(true)}
                        >{title}</StyledText>
                        <DeleteIcon onClick={handleDelete} />
                    </DisplayContainer>
                )
                }
            </LabelContainer>
        </ComponentWrapper>
    );
}

export default ChecklistItem;