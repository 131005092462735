import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { toast } from 'react-toastify';
import { Icon, Segment } from 'semantic-ui-react';
import { useLightbox } from 'simple-react-lightbox';
import styled from 'styled-components';
import { useStore } from '../../../app/stores/store';
import ChatAttachmentHiddenImage from '../chat/ChatAttachmentHiddenImage';
import ChatAttachmentImage from '../chat/ChatAttachmentImage';
import AttachmentLoadingIndicator from './AttachmentLoadingIndicator';

//#region Styling
const StyledSegment = styled(Segment) <{ hasView: boolean, isAttachment: boolean, isMyMessage: boolean }>`
    padding: 6px !important;
    margin: 0px 6px 0 0 !important;
    display: flex;
    flex-direction: flex-end;
    box-shadow:none;
    transition: box-shadow .3s;
    justify-content: left;

    ${p => p.isAttachment ? `
        border-radius: 0;
        margin: 0 0 !important;`
        : ``}

    ${p => (p.hasView ? `
        &:hover{
            margin-top: -1px;
            margin-left: -1px;
            box-shadow: 0 0 11px rgba(33,33,33,.2);
            cursor: pointer;
        }
    ` : '')};
    
`;
const ActionWrappers = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

`;

const StyledIcon = styled(Icon)`
    && {
        box-shadow: none !important;
        &:hover{
            background-color: #eee;
            cursor: pointer;
        }
    }
`;

const FileNameContainer = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 150px;
    `;

const AttachemntDetails = styled.div`
`;

//#endregion

interface Props {
    fileName: string;
    size: number;
    lightBoxIndex: number;
    onRemove?: (fileName: string) => void | undefined;
    isOpen?: boolean;
    attachmentId?: string;
    onDelete?: (attachmentId: string) => void | undefined;
    isLast?: boolean;
    fileType?: string;
    isMyMessage?: boolean;
    isUploading?: boolean;
}

const isImage = (fileName) => {
    return fileName.match(/.(jpg|jpeg|png)$/i);
}

const saveFile = (data, fileName) => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }, 0);
}

const ChatAttachment = ({ fileName, size, lightBoxIndex, onRemove, isOpen, attachmentId, isLast, fileType, isMyMessage, isUploading }: Props) => {
    const { chatAttachmentStore, popOverStore, messageStore } = useStore();
    const { bytesToSize } = messageStore;
    const { getBlob, setIsAttachmentClick } = chatAttachmentStore;
    const { openLightbox, closeLightbox } = useLightbox();

    const handleOnClickAttachment = (event: any) => {
        setIsAttachmentClick(true);
        if (attachmentId) {
            //if (event.target !== event.currentTarget) return;

            if (isImage(fileName)) {
                console.log(lightBoxIndex);
                openLightbox(lightBoxIndex!)
            }

            else {
                let response = getBlob(attachmentId).then(v => {
                    if (v != undefined) {
                        saveFile(v, fileName);
                    }
                });

                toast.promise(response,
                    {
                        pending: `Downloading file`,
                        success: `File downloaded!`,
                        error: `Downloading error!`
                    });

            }
        }
    }

    const renderAttachment = (attachmentId, fileName, size) => {
      
        if (!attachmentId) {
            return (<>
                <FileNameContainer style={{ fontSize: '14px', fontWeight: 600 }}>
                    {fileName}
                </FileNameContainer>
                <small style={{ fontSize: '12px' }}>{size ? bytesToSize(size) : 0}</small>
            </>)
        }

        if (isImage(fileName)) {
            return <>
                <ChatAttachmentImage attachmentId={attachmentId!} />
            </>;
        }
        else {
            return <>
                <FileNameContainer style={{ fontSize: '14px', fontWeight: 600 }}>
                    {fileName}
                </FileNameContainer>
                <small style={{ fontSize: '12px' }}>{size ? bytesToSize(size) : 0}</small>
            </>
        }
    }

    return (
        <>
            {
                isUploading ? <AttachmentLoadingIndicator /> : 
                <StyledSegment attached={attachmentId ? (isLast ? 'bottom' : true) : false}
                    isMyMessage={isMyMessage}
                    onClick={handleOnClickAttachment}
                    hasView={attachmentId != undefined}
                    isAttachment={attachmentId != undefined}>
                    {!isImage(fileName) && <Icon size='big' color='grey' name='file alternate outline' style={{ margin: '7px 5px 7px 0' }} />}
                    <AttachemntDetails className='container-attachment'>
                        {renderAttachment(attachmentId, fileName, size)}
                    </AttachemntDetails>
                    {onRemove && (<ActionWrappers><StyledIcon size='small' name='close' bordered onClick={() => onRemove!(fileName)} /></ActionWrappers>)}
                </StyledSegment>
            }
        </>
    );
}

export default observer(ChatAttachment);