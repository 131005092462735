import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PaginatedTable from '../../app/common/table/PaginatedTable';
import { TextOverflow } from '../../app/common/text/TextOverflow';
import DateHelper from '../../app/helpers/dateHelper';
import { FilterValues } from '../../app/models/common/filterValues';
import { Undertime } from '../../app/models/undertime';
import { useStore } from '../../app/stores/store';
import { history } from '../../index';
import { FaCheck, FaRegEye, FaTimes } from 'react-icons/fa';

const dateHelper = new DateHelper();
const swal = withReactContent(Swal);

const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

const UndertimeRequestList = () => {
    const { undertimeStore } = useStore();
    const { filterUndertimes, isUndertimeListLoading, undertimes,
        undertimesTotal, approveUndertime, rejectUndertime } = undertimeStore;

    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 20,
        pageNumber: 1,
        searchKeyword: ""
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    const handleSearch = (searchText: string) => {
        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText });
        }
    }

    useEffect(() => {
        filterUndertimes(filterValues);
    }, [filterValues, filterUndertimes])

    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    Undertimes
                </StyledHeader>
                <PaginatedTable<Undertime>
                    columns={[
                        { title: 'Employee', field: 'user.name' },
                        {
                            title: 'From',
                            cellStyle: { width: '300px' },
                            render: (data: Undertime) => dateHelper.formatLocalDateTime(data.from.toString())
                        },
                        {
                            title: 'To',
                            cellStyle: { width: '300px' },
                            render: (data: Undertime) => dateHelper.formatLocalDateTime(data.to.toString())
                        },
                        {
                            title: 'Duration',
                            render: (data: Undertime) => {
                                let to = moment(data.to);
                                let from = moment(data.from);
                                let duration = to.diff(from);
                                return moment.utc(duration).format('HH:mm:ss');

                            }
                        },
                        {
                            title: 'Reason',
                            render: (data: Undertime) => (
                                <TextOverflow style={{ width: '200px' }}>{data.reason}</TextOverflow>
                            )
                        },
                        {
                            title: 'HR Approval',
                            field: 'undertimeStatus.name'
                        },
                        {
                            title: 'TL Approval',
                            field: 'teamLeadApprovalStatus.name'
                        },
                        {
                            title: 'Status',
                            field: 'clientApprovalStatus.name'
                        },
                    ]}
                    isLoading={isUndertimeListLoading}
                    data={undertimes!}
                    total={undertimesTotal}
                    onChangePage={handleOnChangePage}
                    pageSize={20}
                    pageNumber={filterValues.pageNumber}
                    onSearchChange={handleSearch}
                    toolbar={true}
                    search={true}
                    sorting={false}
                    draggable={false}
                    actions={[rowData => ({
                        icon: () => <FaCheck style={{ color: '#97a4b5', fontSize: '16px' }} />,
                        tooltip: 'Approve',
                        onClick: e => {
                            swal.fire({
                                title: 'Approval Confirmation',
                                text: "Are you sure you want to approve this undertime request?",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Confirm'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    approveUndertime(rowData.id, filterValues);
                                }
                            });
                        },
                        hidden: rowData.clientApprovalStatus.name != 'Pending',
                    }),
                    rowData => ({
                        icon: () => <FaTimes style={{ color: '#97a4b5', fontSize: '16px' }} />,
                        tooltip: 'Reject',
                        onClick: e => {
                            swal.fire({
                                title: 'Rejection Confirmation',
                                text: "Are you sure you want to reject this undertime request?",
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Confirm'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    rejectUndertime(rowData.id, filterValues);
                                }
                            });
                        },
                        hidden: rowData.clientApprovalStatus.name != 'Pending',
                    }),
                    rowData => ({
                        icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                        tooltip: 'View Details',
                        onClick: e => {
                            history.push(`/requests/undertime-requests/${rowData.id}`)
                        },
                        iconProps: {
                            color: 'primary', style: { fontSize: '1em' }
                        },
                    }),
                    ]}
                />
            </StyledContainer>
        </>

    );
};

export default observer(UndertimeRequestList);