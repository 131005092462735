import { Popover } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { Button, Divider, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { CardChecklistFormValues } from '../../../app/models/cardChecklist';
import { useStore } from '../../../app/stores/store';
import TextAreaInput from './TextAreaInput';

//#region Styling
const Menu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;

    & i{
        background:none !important;
    }
`;

const BodyContainer = styled.div`
    padding: 0 12px 12px;
`;

const HeaderContainer = styled.div`
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    padding:8px;
    & h4{
        margin:0 !important;
    }
`;

const ContentWrapper = styled.div`
        && {
        border-radius: 3px;
        box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
        width: 304px;
    }
`;

//#endregion

interface Props {
    cardId: string;
}

const ChecklistMenu = ({ cardId }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const { cardChecklistStore } = useStore();

    const handleClick = (e: MouseEvent) => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);
    const id = open ? 'popover-cardchecklist' : undefined;

    const handleSubmit = (values: { title: string }, { resetForm, setSubmitting }: FormikHelpers<{ title: string }>) => {
        cardChecklistStore.add({
            title: values.title,
            cardId: cardId
        } as CardChecklistFormValues).then(
            result => {
                setSubmitting(false);
                setAnchorEl(null);
            },
            reason => {
                console.log(reason)
                setSubmitting(false);
            }
        );
    }

    return (
        <>
            <Menu
                icon='check square outline'
                labelPosition='left'
                content='Checklist'
                onClick={handleClick}
                fluid />

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                elevation={1}
            >
                <Formik
                    enableReinitialize
                    initialValues={{ title: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={Yup.object({
                        title: Yup.string().required('Checklist Title is required.').max(512, 'Checklist Title field must be at most 512 characters')
                    })}>
                    {
                        ({ handleSubmit, isSubmitting, dirty, isValid, values }) => (
                            <ContentWrapper>
                                <HeaderContainer>
                                    <Header>
                                        Add Checklist
                                    </Header>
                                    <Divider />
                                </HeaderContainer>
                                <BodyContainer>
                                    <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                                        <TextAreaInput
                                            placeholder='Enter checklist title...'
                                            rows={1}
                                            label='Checklist Title'
                                            name='title' />
                                        <Button
                                            type='submit'
                                            loading={isSubmitting}
                                            disabled={!isValid || isSubmitting || !dirty}
                                            size='small'
                                            compact
                                            color='blue'>Add</Button>
                                    </Form>
                                </BodyContainer>
                            </ContentWrapper>
                        )
                    }
                </Formik>
            </Popover>
        </>
    );
}

export default ChecklistMenu;