import { observer } from 'mobx-react-lite';
import React from 'react';
import { BsCheck2Square } from 'react-icons/bs';
import { Item } from 'semantic-ui-react';
import styled from 'styled-components';
import { CardChecklist } from '../../../app/models/cardChecklist';
import ChecklistItem from './ChecklistItem';
import ChecklistProgress from './ChecklistProgress';
import ChecklistTitleInput from './ChecklistTitleInput';
import ItemTitleInput from './ItemTitleInput';

//#region Styling
const ComponentWrapper = styled.div`
    && {
        margin-bottom: 24px;
        display:block
    }
`;

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 !important;
        width:100%;
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:16px 0 0 0 !important;
        width:100%;
        display:flex;
    }
`;

const ChecklistIcon = styled(BsCheck2Square)`
    margin: 4px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

//#endregion

interface Props {
    checklist: CardChecklist;
}

const Checklist = ({ checklist }: Props) => {
    return (
        <ComponentWrapper>
            <StyledItem>
                <ChecklistIcon />
                <ItemContent>
                    <ChecklistTitleInput checklist={checklist} />
                </ItemContent>
            </StyledItem>
            <ChecklistProgress items={checklist.items ?? []} />
            {checklist.items && checklist.items.map(c => (
                <ChecklistItem key={c.id} checklistItem={c} />
            ))}
            <ItemTitleInput cardId={checklist.cardId} cardChecklistId={checklist.id} />
        </ComponentWrapper>
    );
}

export default observer(Checklist);