import { useField } from 'formik';
import * as React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Form, Label, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';

interface Props {
    placeholder: string;
    name: string;
    label?: string;
    type?: string;
    rows: number;
    isResizable?: boolean;
}

const StyledTextArea = styled(TextareaAutosize) <{ isResizable?: boolean }>`
        resize: ${p => p.isResizable ? `vertical` : `none`} !important;
        width:100% !important;
        overflow: hidden !important;
        overflow-wrap: break-word !important;
        background: #0000 !important;
        border-radius: 3px !important;
        line-height: 24px !important;
        min-height: 24px !important;
        padding: 4px 8px !important;
        background-color: #fff !important;
        box-shadow: inset 0 0 0 2px #0079bf !important;
        border: none !important;
        outline:none !important;
`;

export default function TextAreaInput(props: Props) {
    const [field, meta] = useField(props.name);
    return (
        <Form.Field autoFocus error={meta.touched && !!meta.error} style={{ textAlign: 'left' }}>
            <label style={{ textAlign: 'left', color: '#6a7e99', fontSize: '12px' }}>
                {props.label}
            </label>
            <StyledTextArea autoFocus {...field} {...props} />
            {meta.touched && meta.error ? (<Label style={{ marginTop: '10px' }} basic color='red'>{meta.error}</Label>) : null}
        </Form.Field>
    );
}