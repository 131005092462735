import * as React from 'react';
import { FaRss } from 'react-icons/fa';
import { FiClipboard, FiFileText, FiTrello, FiUsers } from "react-icons/fi";

export const SidebarData = [
    {
        id: 0,
        text: "Newsfeed",
        path: "/newsfeed",
        icon: <FaRss style={{ fontSize: '1.7rem' }} />,
    },
    {
        id: 1,
        text: "Employee",
        path: "/employees",
        icon: <FiUsers style={{ fontSize: '1.7rem' }} />,
    },
    {
        id: 2,
        text: "Daily Report",
        path: "/daily-reports",
        icon: <FiClipboard style={{ fontSize: '1.7rem' }} />,
    },
    {
        id: 3,
        text: "Request", icon: <FiFileText style={{ fontSize: '1.7rem' }} />,
        subNav: [
            {
                text: 'Leave Request',
                path: "/requests/leave-requests",
            },
            {
                text: 'Overtime',
                path: "/requests/overtime-requests",
            },
            {
                text: 'Undertime',
                path: "/requests/undertime-requests",
            },
            {
                text: 'DTR Summary',
                path: "/requests/dtr-summary",
            },
        ]
    },
    {
        id: 4,
        text: 'Workspaces',
        icon: <FiTrello style={{ fontSize: '1.7rem' }} />,
        path: "/workspaces",
    },
    //{
    //    id: 5,
    //    text: "External User",
    //    path: "/external-users",
    //    icon: <FiUsers style={{ fontSize: '1.7rem' }} />,
    //}
];


export const PortalMenuData = [
];

