import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { Undertime } from '../models/undertime';

export default class UndertimeStore {
    undertimes: Undertime[] = [];
    undertimesTotal: number = 0;
    isUndertimeListLoading: boolean = false;
    isInitialLoading: boolean = false;

    undertime: Undertime | undefined;
    isUndertimeDetailsLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    filterUndertimes = async (filters: FilterValues, isInitial?: boolean) => {
        if (isInitial) this.isInitialLoading = true;
        this.isUndertimeListLoading = true;

        try {
            const { total, data } = await agent.UndertimeRequests.filter({
                id: filters.id,
                pageNumber: filters.pageNumber,
                pageSize: filters.pageSize,
                searchKeyword: filters.searchKeyword

            });
            runInAction(() => {
                this.undertimes = data;
                this.undertimesTotal = total;
                this.isUndertimeListLoading = false;
                this.isInitialLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isUndertimeListLoading = false;
            this.isInitialLoading = false;
        }
    }

    loadUndertime = async (id: string) => {
        this.isUndertimeDetailsLoading = true;
        try {
            const undertime = await agent.UndertimeRequests.get(id);
            runInAction(() => {
                this.undertime = undertime;
                this.isUndertimeDetailsLoading = false;

            })
        } catch (e) {
            console.log(e);
            this.isUndertimeDetailsLoading = false;
        }
    }

    approveUndertime = async (id: string, filters: FilterValues) => {
        this.isUndertimeListLoading = true;

        try {
            await agent.UndertimeRequests.approve(id);
            await this.filterUndertimes(filters);
            toast.success('Undertime approved.');
            this.isUndertimeDetailsLoading = false;

        } catch (e) {
            console.log(e);
            this.isUndertimeDetailsLoading = false;

        }
    }

    rejectUndertime = async (id: string, filters: FilterValues) => {
        this.isUndertimeDetailsLoading = true;
        try {
            await agent.UndertimeRequests.reject(id);
            await this.filterUndertimes(filters);
            toast.success('Undertime rejected.');
            this.isUndertimeDetailsLoading = false;
        } catch (e) {
            console.log(e);
            this.isUndertimeDetailsLoading = false;

        }
    }
}