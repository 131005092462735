import { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import DateHelper from '../helpers/dateHelper';
import { AccessToken } from '../models/authorization';
import { Board, BoardFormValues } from '../models/board';
import { Card, CardFormValues } from '../models/card';
import { CardAttachment } from '../models/cardAttachment';
import { CardDate as CardSchedule } from '../models/cardDate';
import { CardGroup, CardGroupFormValues } from '../models/cardGroup';
import { FilterValues } from '../models/common/filterValues';
import { Conversation, PostConversation } from '../models/conversation';
import { Education, Employee, EmployeeFilterValues, Schedule, WorkExperience } from '../models/employee';
import { DailyReport, DailyReportFilterValues } from '../models/employeeDailyReport';
import { LeaveRequest } from '../models/leaveRequest';
import { Message, PostMessage, PostMessageAttachment, PostMessageResponce } from '../models/message';
import { Option } from '../models/option';
import { Overtime } from '../models/overtime';
import { Post, PostAttachment, UploadPostAttachmentForm, PostForm, PostLikes as PostLikesModel, PostComment, EditPostForm } from '../models/post';
import { SubClient, SubClientFormValues } from '../models/subClient';
import { TimeRecord, TimeRecordFilterValues } from '../models/timeRecord';
import { Undertime } from '../models/undertime';
import { RefreshToken, ResetPasswordFormValues, User, UserFormValues } from '../models/user';
import { WorkSpace, WorkSpaceFormValues } from '../models/workSpace';
import { WorkSpaceType, WorkSpaceTypeFormValues } from '../models/workSpaceType';
import { WorkSpaceUser, WorkSpaceUserFormValues } from '../models/workSpaceUser';
import axiosService from './axios-service';

const dateHelper = new DateHelper();

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, config?: AxiosRequestConfig) => axiosService.get<T>(url, config).then(responseBody),
    upload: <T>(url: string, formData: FormData, config?: AxiosRequestConfig) => axiosService.post<T>(url, formData, config).then(responseBody),
    post: <T>(url: string, body: {}) => axiosService.post<T>(url, body).then(responseBody),
    put: <T>(url: string, body: {}) => axiosService.put<T>(url, body).then(responseBody),
    patch: <T>(url: string, body: {}) => axiosService.patch<T>(url, body).then(responseBody),
    delete: <T>(url: string) => axiosService.delete<T>(url).then(responseBody),
}

const Account = {
    login: (user: UserFormValues) => requests.post<AccessToken>('/authenticate/user', user),
    forgotPassword: (credentials: { username: string }) => requests.post('/Authenticated/ClientAccounts/ForgotPassword', credentials),
    resetPassword: (credentials: ResetPasswordFormValues) => requests.post<void>('/Users/ResetPassword', credentials),
    current: () => requests.get<User>('/Authenticated/ClientAccounts'),
    getProfilePhoto: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`Authenticated/Clients/GetEmployeeProfilePicture/${id}`, config),
    refreshToken: (oldToken: RefreshToken) => requests.post<User>('/authenticate/RefreshToken', oldToken),
}

const Employees = {
    filter: (query: FilterValues) => requests.get<{ total: number, employees: Employee[] }>
        (`/Authenticated/Clients/FilterEmployees?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    filterEmployeesSelectedDetails: (query: FilterValues) => requests.get<{ total: number, employees: Employee[] }>
        (`/Authenticated/Clients/FilterEmployeesSelectedDetails?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    filterDailyReports: (query: FilterValues) => requests.get<{ total: number, data: DailyReport[] }>
        (`/Authenticated/Clients/FilterEmployeeDailyReports?id=${query.id}&pageNumber=${query.pageNumber}&pageSize=${query.pageSize}`),
    filterDTR: (query: FilterValues) => requests.get<{ total: number, data: TimeRecord[] }>
        (`/Authenticated/Clients/FilterEmployeeTimeRecords?id=${query.id}&pageNumber=${query.pageNumber}&pageSize=${query.pageSize}`),
    loadPersonalDetails: (userId: string) => requests.get<Employee>(`/Authenticated/Clients/GetEmployee/${userId}`),
    loadWorkExperiences: (userId: string) => requests.get<WorkExperience[]>(`/Authenticated/Clients/GetEmployeeWorkExperiences/${userId}`),
    loadEducationRecords: (userId: string) => requests.get<Education[]>(`/Authenticated/Clients/GetEducationRecords/${userId}`),
    loadSchedule: (userId: string) => requests.get<Schedule>(`/Authenticated/Clients/GetEmployeeSchedule/${userId}`),
    filterByDepartment: (query: EmployeeFilterValues) => requests.post<{ total: number, employees: Employee[] }>
        (`/Authenticated/Clients/FilterEmployeesByDepartment`, query),
    getAllDepartment: () => requests.get<{ total: number, department: any }>
        (`/Authenticated/Clients/GetAllDepartmentByClient`),
    getNonWorkspaceMembers: (workspaceId: string) => requests.get<{ total: number, employees: Employee[] }>
        (`/Authenticated/Clients/GetNonWorkspaceMembers?workspaceId=${workspaceId}`),
    getUpComingBirthdays: (clientId: string) => requests.get<User[]>(`/Authenticated/Employees/GetUpcommingBirthdaysAsync?clientId=${clientId}`)
}
  
const Common = {
    loadGenders: () => requests.get<Option[]>('Authenticated/Genders/GetAllAsync'),
    loadNationalities: () => requests.get<Option[]>('Authenticated/Nationalities/GetAllAsync'),
    loadCivilStatuses: () => requests.get<Option[]>('Authenticated/CivilStatuses/GetAllAsync'),
    loadDepartments: () => requests.get<Option[]>('Authenticated/Departments/GetAllAsync'),
}

const LeaveRequests = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: LeaveRequest[] }>
        (`/authenticated/clients/leave-requests?pageNumber=${query.pageNumber}
            &pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    approve: (id: string) => requests.patch(`/Authenticated/LeaveRequests/ClientApprove/${id}`, {}),
    reject: (id: string) => requests.patch(`/Authenticated/LeaveRequests/ClientReject/${id}`, {}),
    get: (id: string) => requests.get<LeaveRequest>(`/Authenticated/Clients/GetLeaveRequest/${id}`),
}

const OvertimeRequests = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: Overtime[] }>
        (`/authenticated/clients/overtimes?pageNumber=${query.pageNumber}
            &pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    approve: (id: string) => requests.patch(`/Authenticated/Overtimes/ClientApprove/${id}`, {}),
    reject: (id: string) => requests.patch(`/Authenticated/Overtimes/ClientReject/${id}`, {}),
    get: (id: string) => requests.get<Overtime>(`/Authenticated/Clients/GetOvertime/${id}`),
}

const UndertimeRequests = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: Undertime[] }>
        (`/authenticated/clients/undertimes?pageNumber=${query.pageNumber}
            &pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    approve: (id: string) => requests.patch(`/Authenticated/Undertimes/ClientApprove/${id}`, {}),
    reject: (id: string) => requests.patch(`/Authenticated/Undertimes/ClientReject/${id}`, {}),
    get: (id: string) => requests.get<Undertime>(`/Authenticated/Clients/GetUndertime/${id}`),
}

const DailyReports = {
    filter: (filters: DailyReportFilterValues) => requests.post<{ total: number, data: DailyReport[] }>
        (`/Authenticated/Clients/FilterDailyReports`, filters),
}

const WorkSpaceTypes = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: WorkSpaceType[] }>
        (`/Authenticated/WorkSpaceType/FilterAsync?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}`),
    add: (workSpaceType: { name: string }) => requests.post<string>('/Authenticated/WorkSpaceType', workSpaceType),
    update: (workSpaceType: WorkSpaceTypeFormValues) => requests.put(`/Authenticated/WorkSpaceType/${workSpaceType.id}`, workSpaceType),
    get: (id: string) => requests.get<WorkSpaceType>(`/Authenticated/WorkSpaceType/${id}`),
    getAll: () => requests.get<WorkSpaceType[]>(`/Authenticated/WorkSpaceType`),
    delete: (id: string) => requests.delete(`/Authenticated/WorkSpaceType/${id}`),
}

const WorkSpaces = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: WorkSpace[] }>
        (`/Authenticated/WorkSpaces/FilterAsync?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    add: (workSpace: WorkSpaceFormValues) => requests.post<string>('/Authenticated/WorkSpaces', workSpace),
    update: (workSpace: WorkSpaceFormValues) => requests.put(`/Authenticated/WorkSpaces/${workSpace.id}`, workSpace),
    get: (id: string) => requests.get<WorkSpace>(`/Authenticated/WorkSpaces/${id}`),
    delete: (id: string) => requests.delete(`/Authenticated/WorkSpaces/${id}`),
}

const WorkSpaceUsers = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: WorkSpaceUser[] }>
        (`/Authenticated/UserWorkSpace/FilterAsync?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}&id=${query.id}`),
    add: (workSpaceUser: WorkSpaceUserFormValues) => requests.post<string>('/Authenticated/WorkSpaceUsers', workSpaceUser),
    addMultiple: (workSpaceId: string, userIds: string[]) => requests.post<string>('/Authenticated/UserWorkSpace/AddMultiple', { workSpaceId: workSpaceId, userIds: userIds }),
    update: (workSpaceUser: WorkSpaceUserFormValues) => requests.put(`/Authenticated/UserWorkSpace/${workSpaceUser.id}`, workSpaceUser),
    get: (id: string) => requests.get<WorkSpaceUser>(`/Authenticated/UserWorkSpace/${id}`),
    delete: (id: string) => requests.delete(`/Authenticated/UserWorkSpace/${id}`),
    deleteUserWorkSpace: (memberId: string, workSpaceId: string) => requests.delete(`/Authenticated/UserWorkSpace/delete/${memberId}/${workSpaceId}`),
}

const SubClients = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: SubClient[] }>
        (`/Authenticated/Clients/FilterSubClientsAsync?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}`),
    add: (subClient: SubClientFormValues) => requests.post<string>('/Authenticated/WorkSpaces', subClient),
    update: (subClient: SubClientFormValues) => requests.put(`/Authenticated/SubClientAccounts/${subClient.id}`, subClient),
    get: (id: string) => requests.get<WorkSpace>(`/Authenticated/SubClientAccounts/${id}`),
    delete: (id: string) => requests.delete(`/Authenticated/SubClientAccounts/${id}`),
}

const Boards = {
    filter: (query: FilterValues) => requests.get<{ total: number, data: Board[] }>
        (`/Authenticated/Boards/FilterAsync?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}&workSpaceId=${query.id}`),
    add: (board: BoardFormValues) => requests.post<string>('/Authenticated/Boards', board),
    update: (board: BoardFormValues) => requests.put(`/Authenticated/Boards/${board.id}`, board),
    get: (id: string) => requests.get<Board>(`/Authenticated/Boards/${id}`),
    getAll: (workSpaceId: string) => requests.get<Board[]>(`/Authenticated/Boards?workSpaceId=${workSpaceId}`),
    delete: (id: string) => requests.delete(`/Authenticated/Boards/${id}`),
}

const DtrSummary = {
    filter: (query: TimeRecordFilterValues) => requests.get<{ total: number, data: TimeRecord[] }>
        (`/Authenticated/Clients/FilterEmployeeDtr?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}
            &searchKeyword=${query.searchKeyword}&departmentId=${query.department}&startDate=${query.startDate ? moment(query.startDate).format('YYYY-MM-DD') : ''}
            &endDate=${query.endDate ? moment(query.endDate).format('YYYY-MM-DD') : ''}`),
}

const CardGroups = {
    add: (cardGroup: CardGroupFormValues) => requests.post<string>('/Authenticated/CardGroups', cardGroup),
    update: (cardGroup: CardGroupFormValues) => requests.put(`/Authenticated/CardGroups/${cardGroup.id}`, cardGroup),
    get: (id: string) => requests.get<CardGroup>(`/Authenticated/CardGroups/${id}`),
    getAll: (boardId: string) => requests.get<CardGroup[]>(`/Authenticated/CardGroups?boardId=${boardId}`),
    delete: (id: string) => requests.delete(`/Authenticated/CardGroups/${id}`),
}

const Cards = {
    add: (card: CardFormValues) => requests.post<string>('/Authenticated/Cards', card),
    update: (card: CardFormValues) => requests.put(`/Authenticated/Cards/${card.id}`, card),
    get: (id: string) => requests.get<Card>(`/Authenticated/Cards/${id}`),
    getAll: (cardGroupId: string) => requests.get<Card[]>(`/Authenticated/Cards?cardGroupId=${cardGroupId}`),
    delete: (id: string) => requests.delete(`/Authenticated/Cards/${id}`),
}

const Conversations = {
    filter: (query: FilterValues) => requests.get<{ total: number, conversations: Conversation[] }>
        (`/Authenticated/Conversations/Filter?pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    get: (id: string) => requests.get<Conversation>(`/Authenticated/Conversations/${id}`),
    add: (coversation: PostConversation) => requests.post<string>('/Authenticated/Conversations', coversation),
    updateIsRead: (id: string, isReadRequest: { isRead: boolean }) => requests.put<string>(`/Authenticated/Conversations/UpdateIsRead/${id}`, isReadRequest),
    updateReadReceipt: (id: string) => requests.put<string>(`/Authenticated/Conversations/UpdateReadReceipt/${id}`, {}),
    updateNotificationReceipt: (request: { ids: string[] }) => requests.put<string>(`/Authenticated/Conversations/UpdateMultipleNotificationReceipt/`, request),

    filterEmployeeByConversation: (conversataionId, query: FilterValues) => requests.get<{ total: number, employees: Employee[] }>
        (`/Authenticated/Conversations/FilterEmployeesByConversation?conversationId=${conversataionId}&pageNumber=${query.pageNumber}&pageSize=${query.pageSize}&searchKeyword=${query.searchKeyword}`),
    getUsersByConverSation: (conversationId: string) => requests.get<{ employees: Employee[] }>(`/Authenticated/Conversations/GetUsersByConversation/${conversationId}`),
    addUsersToConversation: (conversationId: string, users: string[]) => requests.post<any>('/Authenticated/Conversations/AddUsersToConversationAsync', { conversationId: conversationId, userIds: users }),
    deleteUser: (conversationId: string, userId: string) => requests.delete(`Authenticated/Conversations/DeleteMember/${conversationId}/${userId}`),

    rename: (conversationId: string, conversationName: string) => requests.post<any>('/authenticated/conversations/Rename', {
        conversationId: conversationId,
        conversationName: conversationName,
    }),
}

const Messages = {
    getAll: (id: string) => requests.get<Message[]>(`/Authenticated/Messages/Conversation/${id}`),
    add: (message: PostMessage) => requests.post<PostMessageResponce>('/Authenticated/Messages', message),
    addMessageAttachment: (content: PostMessageAttachment) => {
        let formData = new FormData();

        for (let i = 0; i < content.files.length; i++) {
            formData.append(`Files`, content.files[i])
        }
        for (let i = 0; i < content.receiversId.length; i++) {
            formData.append(`receiversId`, content.receiversId[i])
        }

        formData.append('Content', content.content);
        formData.append('LoadingId', content.loadingId!);

        if (content.conversationId)
            formData.append('ConversationId', content.conversationId);
        if (content.connectionId)
            formData.append('ConnectionId', content.connectionId);

        return requests.upload<PostMessageAttachment>('/authenticated/messages/attachment', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
}

const CardAttachments = {
    upload: (file: Blob, cardId: string) => {
        let formData = new FormData();
        formData.append('File', file);
        formData.append('CardId', cardId);
        return requests.upload<CardAttachment>('/Authenticated/CardAttachments', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    uploadMultiple: (files: File[], cardId: string, clientId: string) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }
        formData.append('CardId', cardId);
        formData.append('ClientId', clientId);
        return requests.upload<string>('/Authenticated/CardAttachments/upload-multiple', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    getAttachment: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`Authenticated/CardAttachments/GetBlobAttachment/${id}`, config),
}

const CommentAttachments = {
    add: (cardId: string, files: File[], message: string, clientId: string) => {
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);
        formData.append('CardId', cardId);
        formData.append('ClientId', clientId);

        return requests.upload<string>('/Authenticated/CardComment', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    update: (cardId: string, commentId: string, files: File[], message: string, clientId: string) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);
        formData.append('CardId', cardId);
        formData.append('CommentId', commentId);
        formData.append('ClientId', clientId);

        return requests.upload<string>('/Authenticated/CardComment/Update', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    getAttachment: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`Authenticated/CardComment/GetBlobAttachment/${id}`, config),
}
const CardUser = {
    getAll: (cardId: string, workSpaceId: string, clientId: string) => requests.get<User[]>(`Authenticated/carduser/unassigned-members?cardId=${cardId}&workSpaceId=${workSpaceId}&clientId=${clientId}`),
    getAllCardMembers: (cardId: string) => requests.get<User[]>(`Authenticated/CardUser/${cardId}/Users`),
    addMultiple: (cardId: string, userIds: string[]) => requests.post<string>('/Authenticated/CardUser/AddMultiple', { cardId: cardId, userIds: userIds }),
    delete: (cardId: string, userId: string) => requests.delete(`Authenticated/carduser/remove-member?cardId=${cardId}&userId=${userId}`),
}

const CardDate = {
    addSchedule: (request) => requests.post<any>('/Authenticated/CardSchedule', request),
    getSchedule: (id: string) => requests.get<CardSchedule>(`Authenticated/CardSchedule/getByCard/${id}`),
    deleteSchedule: (id: string) => requests.delete(`Authenticated/cardSchedule/${id}`),
}

const ChatAttachments = {
    add: (files: File[], message: string) => {
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);

        return requests.upload<string>('/Authenticated/Message', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    update: (cardId: string, commentId: string, files: File[], message: string, clientId: string) => {
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append(`Files`, files[i])
        }

        formData.append('Message', message);
        formData.append('CardId', cardId);
        formData.append('CommentId', commentId);
        formData.append('ClientId', clientId);

        return requests.upload<string>('/Authenticated/CardComment/Update', formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        });
    },
    getAttachment: (id: string, config: AxiosRequestConfig) => requests.get<Blob>(`authenticated/messages/attachment/${id}`, config),
}

const Posts = {
    getById: (id: string) => requests.get<Post>(`Authenticated/Posts/GetAsync/${id}`),
    filter: (pageNumber: number, pageSize: number, clientId?: string) => requests.get<Post[]>(`Authenticated/Posts/GetAsync?pageNumber=${pageNumber}&pageSize=${pageSize}&clientId=${clientId}`),
    getAttachments: (id: string) => requests.get<PostAttachment[]>(`Authenticated/Posts/GetAttachmentsAsync/${id}`),
    post: (data: PostForm) => requests.post<string>('Authenticated/Posts/', data),
    update: (data: EditPostForm) => requests.put<string>('Authenticated/Posts/' + data.id, data),
    getLikes: (postId: string) => requests.get<PostLikesModel>(`/Authenticated/Posts/GetLikesAsync/${postId}`),
    getComments: (postId: string) => requests.get<PostComment[]>(`/Authenticated/Posts/GetCommentsAsync/${postId}`),
    delete: (postId: string) => requests.delete(`/Authenticated/Posts/${postId}`),
}

const PostAttachments = {
    get: (id: string) => requests.get<Blob>(`Authenticated/PostAttachments/${id}`, { responseType: 'blob' }),
    upload: (data: FormData) => requests.upload<string>('Authenticated/PostAttachments/', data, {
        headers: { 'Content-type': 'multipart/form-data' }
    }),
    delete: (id: string) => requests.delete(`Authenticated/PostAttachments/${id}`),
}

const PostLikes = {
    post: (postId: string) => requests.post<string>(`/Authenticated/PostLikes`, { postId }),
    delete: (likeId: string) => requests.delete(`/Authenticated/PostLikes/${likeId}`),
}

const PostComments = {
    post: (data: { postId: string, message: string }) => requests.post<string>(`/Authenticated/PostComments`, data),
    update: (data: { id: string, message: string }) => requests.put(`/Authenticated/PostComments/${data.id}`, data),
    delete: (id: string) => requests.delete(`/Authenticated/PostComments/${id}`),

}

const agent = {
    Account,
    Common,
    Employees,
    SubClients,
    LeaveRequests,
    OvertimeRequests,
    UndertimeRequests,
    DailyReports,
    WorkSpaceTypes,
    WorkSpaces,
    WorkSpaceUsers,
    Boards,
    DtrSummary,
    CardGroups,
    Cards,
    Conversations,
    Messages,
    CardAttachments,
    CommentAttachments,
    ChatAttachments,
    CardUser,
    CardDate,
    Posts,
    PostAttachments,
    PostLikes,
    PostComments
}

export default agent;
