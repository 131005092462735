import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Item, ItemContent } from 'semantic-ui-react';
import styled from 'styled-components';
import { ScheduleNotification } from '../../app/models/scheduleNotification';

//#region Styling

const StyledItemContent = styled(ItemContent)`
&& {
    border-top: 1px solid #e5e5e5 !important;
    border-bottom: 1px solid #e5e5e5 !important;
    padding: 10px !important;
}
`;

const StyledItem = styled(Item)`
&& {
    margin: 0 !important;
}
`;

const StyledItemTitle = styled.span`
&& {
    display: block;
    font-weight: bold;
    font-size: 15px;
    font-family: sans-serif;
}
`;

const StyledItemDate = styled.span`
&& {
    display: block;
    font-size: 13px;
    font-family: sans-serif;
    color: #939393;
}
`;

const CenteredSpan = styled.span`
&& {
    display: inline-block;
    float: left;
    margin-left: 31px;
}
`;

const StyledItemGroup = styled(Item.Group)`
&& {
    max-height: 245px;
    overflow-y: auto;
}
`;

//#endregion


interface Props {
    notifications: ScheduleNotification[];
}


const NotificationList = ({ notifications }: Props) => {
    notifications = [...notifications].sort((a: ScheduleNotification, b: ScheduleNotification) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  
    return (
        <>
            <StyledItemGroup>
                {notifications.map((notification, index) => {
                    let date = new Date(notification.date + 'Z'),
                        formattedDate = moment(date).format('MMM D, yyyy hh:mm a');

                  

                return <StyledItem key={index}>
                    <StyledItemContent>
                        <CenteredSpan>
                            <StyledItemTitle>{notification.cardTitle}</StyledItemTitle>
                            <StyledItemDate>{formattedDate}</StyledItemDate>
                        </CenteredSpan>
                    </StyledItemContent>
                </StyledItem>
                })}
            </StyledItemGroup>
        </>
    );
}

export default observer(NotificationList);