import { MaterialTableProps, Query } from 'material-table';
import { makeAutoObservable, runInAction } from 'mobx';
import { MutableRefObject } from 'react';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { LeaveRequest } from '../models/leaveRequest';

export default class LeaveRequestStore {
    leaveRequests: LeaveRequest[]=[];
    leaveRequestsTotal: number = 0;
    isLeaveRequestsLoading: boolean = false;
    isInitialLoading: boolean = false;

    leaveRequest: LeaveRequest | undefined;
    isLeaveRequestDetailsLoading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    filterLeaveRequests = async (filters: FilterValues, isInitial?: boolean) => {
        if (isInitial) this.isInitialLoading = true;
        this.isLeaveRequestsLoading = true;

        try {
            const { total, data } = await agent.LeaveRequests.filter({
                id: filters.id,
                pageNumber: filters.pageNumber,
                pageSize: filters.pageSize,
                searchKeyword: filters.searchKeyword

            });
            runInAction(() => {
                this.leaveRequests = data;
                this.leaveRequestsTotal = total;
                this.isLeaveRequestsLoading = false;
                this.isInitialLoading = false;
                
            });
        } catch (e) {
            console.log(e);
            this.isLeaveRequestsLoading = false;
            this.isInitialLoading = false;
        }
    }

    loadLeaveRequest = async (id: string) => {
        this.isLeaveRequestDetailsLoading = true;
        try {
            const leaveRequest = await agent.LeaveRequests.get(id);
            runInAction(() => {
                this.leaveRequest = leaveRequest;
                this.isLeaveRequestDetailsLoading = false;

            })
        } catch (e) {
            console.log(e);
            this.isLeaveRequestDetailsLoading = false;

        }
    }

    approveLeaveRequest = async (id: string, filters: FilterValues) => {
        this.isLeaveRequestsLoading = true;

        try {
            await agent.LeaveRequests.approve(id);
            await this.filterLeaveRequests(filters);
            toast.success('Leave Request approved.');
            this.isLeaveRequestsLoading = false;

        } catch (e) {
            console.log(e);
            this.isLeaveRequestsLoading = false;

        }
    }

    rejectLeaveRequest = async (id: string, filters: FilterValues) => {
        this.isLeaveRequestsLoading = true;

        try {
            await agent.LeaveRequests.reject(id);
            await this.filterLeaveRequests(filters);
            toast.success('Leave Request rejected.');
            this.isLeaveRequestsLoading = false;

        } catch (e) {
            console.log(e);
            this.isLeaveRequestsLoading = false;

        }
    }
}