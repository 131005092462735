import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { history } from "../..";
import agent from '../api/agents';
import CookieHelper from '../helpers/cookieHelper';
import { UserFormValues } from "../models/user";
import { store } from "./store";
import jwt_decode from "jwt-decode";
import { JsonWebToken } from '../models/authorization';

const _cookieHelper = new CookieHelper();

export default class AuthStore {
    token: string | null = _cookieHelper.get('jsonWebToken') || null;
    refreshToken: string | null = _cookieHelper.get('refreshToken') || null;
    refreshTokenTimeout: any;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token) {
                    _cookieHelper.set('jsonWebToken', token);
                }
                else {
                    _cookieHelper.delete('jsonWebToken');
                }
            }
        );

        reaction(
            () => this.refreshToken,
            token => {
                if (token) {
                    _cookieHelper.set('refreshToken', token);
                }
                else {
                    _cookieHelper.delete('refreshToken');
                }
            }
        );
    }

    //THIS IS COMPUTED WHENEVER USER ACCESS A PRIVATE PAGE
    get roles(): string[] {
        if (!this.token)
            return [];

        let decoded = <JsonWebToken>jwt_decode(this.token);

        //THIS IS FOR THE MICROSOFT CLAIM ROLE
        let microsoftRole = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

        if (!decoded.role && !microsoftRole)
            return [];

        if (decoded.role) {
            if (typeof decoded.role === 'string')
                return [decoded.role];
            else
                return decoded.role;
        }else {
            if (typeof microsoftRole === 'string')
                return [microsoftRole];
            else
                return microsoftRole as string[];
        }
    }

    setRefreshToken = (refreshToken: string | null) => {
        this.refreshToken = refreshToken;
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    login = async (creds: UserFormValues) => {
        try {
            const accessToken = await agent.Account.login(creds);
            this.setToken(accessToken.jsonWebToken!);
            const user = await agent.Account.current();
            this.startRefreshTokenTimer();
            runInAction(() => {
                this.setRefreshToken(accessToken.refreshToken);
                store.userStore.user = user;
                store.userStore.setDisplayName(user);
                store.commonStore.isCollapseSideBar = false;
                store.commonStore.stopHubConnection();
                store.commonStore.createHubConnection();
                history.push('/');
            });
        } catch (e) {
            console.error(e);
        }
    }

    logout = () => {
        this.setToken(null);
        this.setRefreshToken(null);
        _cookieHelper.delete('jsonWebToken');
        this.resetStates();
        store.postStore.resetPosts();
        history.push('/');
    }

    refreshTheToken = async () => {
        try {
            const accessToken = await agent.Account.refreshToken({
                jsonWebToken: this.token!,
                refreshToken: this.refreshToken!
            });

            runInAction(() => {
                this.setToken(accessToken.jsonWebToken!);
                this.setRefreshToken(accessToken.refreshToken!);
                this.startRefreshTokenTimer();
            })
        } catch (error) {
            console.log(error);
        }
    }

    startRefreshTokenTimer() {
        if (this.token) {
            const jwtToken = JSON.parse(atob(this.token!?.split('.')[1]));
            const expires = new Date(jwtToken.exp * 1000);
            const timeout = expires.getTime() - Date.now() - (30 * 1000);
            this.refreshTokenTimeout = setTimeout(this.refreshTheToken, timeout);
        }
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    private resetStates = () => {
        store.chatStore.newMessageOpen = false;
        store.chatStore.newGroupMessageOpen = false;
        store.popOverStore.closePopover();
        store.messageStore.setMessages([]);
        store.chatStore.hideMessageBox();
        store.userStore.user = null;
        store.chatStore.stopHubConnection();
        store.commonStore.stopHubConnection();
        store.boardStore.stopHubConnection();
    }
}