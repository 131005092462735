import zIndex from '@material-ui/core/styles/zIndex';
import React, { useState } from 'react';
import { DropdownProps, Select } from 'semantic-ui-react';
import styled from 'styled-components';


const ContainerStyled = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;
`;

interface Props {
    dateFilterLabel?: string;
    onOptionFilter?: (value: string) => void | undefined;
    valueProps?: string;
    options: any;
    placeholdder: string;
}

const OptionFilter = ({ dateFilterLabel, onOptionFilter, valueProps, options, placeholdder }: Props) => {

    const [value, setValue] = useState<string|undefined>(valueProps);

    const handleDatesChange = (event: React.SyntheticEvent<HTMLElement>,d:DropdownProps) => {
        if (onOptionFilter) {
            setValue(d.value as string);
            onOptionFilter(d.value as string);
        }
    };
    return (
        <ContainerStyled>
            {dateFilterLabel && (<label style={{ textAlign: 'left', color: '#6a7e99', fontSize: '14px', marginBottom: '4px' }}>
                {dateFilterLabel}
            </label>)}
            <Select
                options={options}
                clearable
                placeholder={placeholdder}
                value={value}
                onChange={handleDatesChange}
                style={{ zIndex:11 }}
            />
        </ContainerStyled>
    );
}

export default OptionFilter;