import { observer } from "mobx-react-lite";
import { Redirect, Route, RouteComponentProps, RouteProps } from "react-router-dom";
import { Container } from "semantic-ui-react";
import styled from 'styled-components';
import AccessDenied from '../../features/errors/AccessDenied';
import Chat from "../common/chat/Chat";
import NavBar from "../common/navbar/NavBar";
import SidebarMenu from "../common/sidebar-menu/SideBarMenu";
import { Role } from "../enums/role.enum";
import { useStore } from "../stores/store";


//#region "Styling"
const StyledAppWrapper = styled.div<{ isCollapse: boolean }>`
        height: 94.1%;
        position: fixed;
        top: 54px;
        left: 240px;
        bottom: 0;
        right: 0;
        background-color: #f3f9ff !important;
        border-bottom-right-radius: 20px;
        transition: left 0.5s ease,top 0.5s ease;

        & .item {
            background: #fff;
            margin-bottom: 10px;
            padding: 15px;
            font-size: 14px;
            line-height: 22px;
        }

          ${p => (p.isCollapse && `
                left: 80px;
                top: 54px;
            `)};
`;

//#endregion "Styling"


interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    roles: Role[];
}

function PrivateRoute({ component: Component, exact, roles, ...rest }: Props) {
    const { userStore, authStore, commonStore: { isCollapseSideBar } } = useStore();

    const { isLoggedIn, user } = userStore;
    const { roles: currentRoles, token } = authStore;

    const hasRequiredRole = user && roles.some(r => currentRoles.includes(r));

    if (!isLoggedIn) {
        return (<Redirect to='/login' />);
    }

    if (isLoggedIn && !hasRequiredRole) {
        return (<AccessDenied />)
    }

    return (
        <Route
            {...rest}
            exact={exact ? exact : false}
            render={(props) => (
                <>
                    <NavBar />
                    <SidebarMenu />
                    <StyledAppWrapper id='app-wrapper' isCollapse={isCollapseSideBar} style={{ overflowY: 'auto' }}>
                        <Container
                            style={{ marginTop: '1em', }}
                            fluid>
                            <Component {...props} />
                        </Container>
                    </StyledAppWrapper>
                    <Chat />
                </>
            )}
        />
    )
}

export default observer(PrivateRoute);