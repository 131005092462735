import { IconButton, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { MdClear } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import AvatarImage from '../../../app/common/image/AvatarImage';
import { User } from '../../../app/models/user';
import { useStore } from '../../../app/stores/store';

interface Props {
    user: User;
    cardId: string;
    createdUserId: string;
}

const useStyle = makeStyles((theme) => ({
 
    btnCircle: {
        position: "absolute",
        top: "-6px",
        right: "-6px",
       
    },
   
}));

const ComponentWrapper = styled.div`
     && {
         margin-right: 6px;
         margin-top: 10px;
         max-width: 3.5em;
         display: inline-block;
         text-align: center;
         position: relative;
     }
`;
const NameWrapper = styled.div`
     && {
        line-height: 1em;
        font-size: 0.75em;
        color: #767373;
        margin-top: 4px;
        overflow-wrap: break-word;
     }
`;


const CardMember = ({ user, cardId, createdUserId }: Props) => {
    const classes = useStyle();
    const { cardMemberStore, } = useStore();
    const { removeMember } = cardMemberStore;
    const handleOnRemove = () => {

        Swal.fire({
            title: 'Remove?',
            text: "Are you sure you want to remove this member?",
            icon: 'warning',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then(async (result) => {
            if (result.isConfirmed) {
                removeMember(cardId, user.id);
                toast.success("Member was removed successfully.");
            }
            
        })
      
    }

    return (
        <ComponentWrapper>
        
            <AvatarImage size='mini' userId={user.id} hasPhoto={user.hasProfilePicture!} style={{ boxShadow: '1px 2px 3px #ccc', padding: '2px', marginRight: '0px',height:'40px', width: '40px' }} >

             </AvatarImage>
            {(user.id == createdUserId && user.clientId) ? null : <IconButton size="small" style={{ width: '20px', height: '20px' }} className={classes.btnCircle} onClick={handleOnRemove} >
                <MdClear />
            </IconButton>}
            
            <NameWrapper> {user.firstName}</NameWrapper>
           
        </ComponentWrapper>
    );
}

export default observer(CardMember);