import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Checkbox, FormGroup, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import TimePickers from '../../../app/common/timepicker/Timepicker';
import { useStore } from '../../../app/stores/store';

//#region Styling
const StyledSectionContainer = styled(Grid.Row)`
&&{
    border: 1px solid #dee2e6;
    margin: 0px 10px 5px 10px;
}
`;

const StyledMainTitle = styled.h5`
&&{
    margin-top: 5px;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
}
`;

const StyledDateTimeTitle = styled.h6`
&&{
    font-family: sans-serif;
    font-size: 13px;
    font-weight: bold;
}
`;

const StyledNotificationTitle = styled.h6`
&&{
    font-family: sans-serif;
    font-size: 13px;
    font-weight: bold;
}
`;

const StyledGrid = styled(Grid.Column)`
&&{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
`;

const StyledTextField = styled(TextField)`
&&{
    width: 144px;
    float: right;
    padding: 0px;
}
`;

const StyledDate = styled(TextField)`
&&{
    width: 144px;
    float: right;
}
`;

const StyledCheckbox = styled(Checkbox)`
&&{
    margin-right: 11px;
}
`;

const StyledValidationMessage = styled.div`
&&{
    color: #f44336;
    font-size: 10px;
    display: block;
    text-align: right;
}
`;

const StyledRecurrence = styled(FormControlLabel)`
&&{
    position: absolute;
    top: -9px;
    left: 23px;
    background: #F4F5F7;
}
`;

const StyledRecurrenceLabel = styled(Typography)`
&&{
    font-size: 11px;
    background: #F4F5F7;
}
`;

const StyledInput = styled.input`
&&{
    width: 27px;
    border: 1px solid grey;
    background: #F4F5F7;
    outline: none !important;
    text-align: center;
    padding: 3px;
    margin: 0 4px;
    font-size: 10px;
}
`;

const StyledOrdinalDropdown = styled.select`
&&{
    width: 62px;
    border: 1px solid grey;
    background: #F4F5F7;
    outline: none !important;
    text-align: center;
    padding: 3px 0;
    margin: 0 4px;
    font-size: 10px;
}
`;

const StyledWeekendDropdown = styled.select`
&&{
    width: 82px;
    border: 1px solid grey;
    background: #F4F5F7;
    outline: none !important;
    text-align: center;
    padding: 3px 0;
    margin: 0 4px;
    font-size: 9px;
}
`;

const StyledMonthDropdown = styled.select`
&&{
    width: 82px;
    border: 1px solid grey;
    background: #F4F5F7;
    outline: none !important;
    text-align: center;
    padding: 3px 0;
    margin: 0 4px;
    font-size: 9px;
}
`;

//#endregion

const validationSchema = yup.object({
    startTime: yup
        .string()
        .required('Start time is required'),
    endTime: yup
        .string()
        .required('End time is required'),
    startDate: yup
        .string()
        .required('Start date is required')
});

interface Props {
    cardId: string;
}

const DateDetails = ({ cardId }: Props) => { 
    const { dateModalStore, cardDateStore } = useStore();
    const { addSchedule, getSchedule, cardDate } = cardDateStore;
    const [duration, setDuration] = useState(30);
    const [recurrenceType, setRecurrenceType] = useState("daily");
    const [recurrencePattern, setRecurrencePattern] = useState(false);
    const [dailyRecurrenceOption, setDailyRecurrenceOption] = useState("first");
    const [monthlyRecurrenceOption, setMonthlyRecurrenceOption] = useState("first");
    const [yearlyRecurrenceOption, setYearlyRecurrenceOption] = useState("first");

    useEffect(() => {
        if (cardId) getSchedule(cardId).then(() => {
            if (cardDate) {
                let start = new Date(cardDate.startTime + 'Z'),
                    end = new Date(cardDate.endTime + 'Z'),
                    durationVal = ((end.getTime() - start.getTime()) / 1000) / 60;
                if (durationVal < 0) {
                    let endTimeWithTomorrowDate = end;
                    endTimeWithTomorrowDate.setDate(endTimeWithTomorrowDate.getDate() + 1);
                    durationVal = ((endTimeWithTomorrowDate.getTime() - start.getTime()) / 1000) / 60;
                }

                setDuration(durationVal);
            }
        });
    }, [cardId]);

    useEffect(() => {
        if (cardDate) {
            formik.resetForm({
                values: {
                    scheduleId: cardDate.id,
                    startTime: moment(new Date(cardDate!.startTime + 'Z')).format('HH:mm'),
                    endTime: moment(new Date(cardDate!.endTime + 'Z')).format('HH:mm'),
                    startDate: moment(new Date(cardDate!.startDate + 'Z')).format('yyyy-MM-DD'),
                    notificationCheckbox: cardDate!.notification.split(',')
                }
            })
        }
    }, [cardDate]);

    const handleFormSubmission = (values) => {
        const [year, month, day] = values.startDate.split('-');
        const formattedDate = [month, day, year].join('/');

        let request = {
            id: values.scheduleId ? values.scheduleId : null,
            cardId,
            startTime: new Date(new Date().toDateString() + ' ' + values.startTime),
            endTime: new Date(new Date().toDateString() + ' ' + values.endTime),
            notification: values.notificationCheckbox.length != 0 ? values.notificationCheckbox.join(",") : '',
            startDate: formattedDate
        };

        addSchedule(request).then(() => {
            dateModalStore.closeModal();
        });
    }

    const handleCheckBoxClick = (e) => {
        e.stopPropagation();
        let label = e.target.parentElement.nextElementSibling;
        label.click();
    }

    const handleStartTimeOnChange = (e) => {
        let baseDate = moment('2022, 1, 1 ' + e.target.value),
            endTime = baseDate.add(30, 'minutes').format('HH:mm');

        formik.handleChange(e);
        formik.setFieldValue('endTime', endTime);

        setTimeout(() => {
            let startTimeVal = e.target.value,
                endTimeVal = document.getElementsByClassName('end-timepicker')[0].querySelector('input')?.value,
                dateToday = new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(),
                durationVal = ((new Date(dateToday + ' ' + endTimeVal).getTime() - new Date(dateToday + ' ' + startTimeVal).getTime()) / 1000) / 60;

            if (durationVal < 0) {
                let endTimeWithTomorrowDate = new Date(dateToday + ' ' + endTimeVal);
                endTimeWithTomorrowDate.setDate(endTimeWithTomorrowDate.getDate() + 1);
                durationVal = ((endTimeWithTomorrowDate.getTime() - new Date(dateToday + ' ' + startTimeVal).getTime()) / 1000) / 60;
            }

            setDuration(durationVal);
        },500);

    }

    const handleEndTimeOnChange = (e) => {
        formik.handleChange(e);

        setTimeout(() => {
            let endTimeVal = e.target.value,
                startTimeVal = document.getElementsByClassName('start-timepicker')[0].querySelector('input')?.value,
                dateToday = new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(),
                durationVal = ((new Date(dateToday + ' ' + endTimeVal).getTime() - new Date(dateToday + ' ' + startTimeVal).getTime()) / 1000) / 60;

            if (durationVal < 0) {
                let endTimeWithTomorrowDate = new Date(dateToday + ' ' + endTimeVal);
                endTimeWithTomorrowDate.setDate(endTimeWithTomorrowDate.getDate() + 1);
                durationVal = ((endTimeWithTomorrowDate.getTime() - new Date(dateToday + ' ' + startTimeVal).getTime()) / 1000) / 60;
            }

            setDuration(durationVal);
        }, 500);
    }

    const handleRecurrenceTypeChange = (e) => {
        setRecurrenceType(e.target.value);
    }

    const handleDailyRecurrenceOptionChange = (e) => {
        setDailyRecurrenceOption(e.target.value);
    }

    const handleMonthlyRecurrenceOptionChange = (e) => {
        setMonthlyRecurrenceOption(e.target.value);
    }

    const handleYearlyRecurrenceOptionChange = (e) => {
        setYearlyRecurrenceOption(e.target.value);
    }

    const handleRecurrencePatternChange = (e) => {
        setRecurrencePattern(e.target.checked);
    }

    const formik = useFormik({
        initialValues: {
            scheduleId: '',
            startTime: '10:00',
            endTime: '10:30',
            startDate: moment(new Date()).format('yyyy-MM-DD'),
            notificationCheckbox: ["15"]
        },
        validationSchema: validationSchema,
        onSubmit: handleFormSubmission
    });

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid>
                    <StyledMainTitle>Schedule Date</StyledMainTitle>
                    <StyledSectionContainer width={16}>
                        <TextField
                            name="scheduleId"
                            type="hidden"
                            value={ formik.values.scheduleId }
                            hidden
                        />
                        <StyledGrid width={9}>
                            <StyledDateTimeTitle>Date & Time</StyledDateTimeTitle>
                            <Grid.Row style={{ marginTop: '5px' }} >
                                <label style={{ lineHeight: 2 }} >Start: </label>
                                <TimePickers
                                    style={{ width: '144px', float: 'right' }}
                                    classes={{ name: 'start-timepicker' }}
                                    name="startTime"
                                    value={formik.values.startTime}
                                    onChange={handleStartTimeOnChange}
                                    error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                                    helperText={formik.touched.startTime && formik.errors.startTime}
                                />
                            </Grid.Row>
                            <StyledValidationMessage>{formik.touched.startTime && formik.errors.startTime}</StyledValidationMessage>
                            <Grid.Row style={{ marginTop: '5px' }} >
                                <label style={{ lineHeight: 2 }} >End: </label>
                                <TimePickers
                                    style={{ width: '144px', float: 'right' }}
                                    classes={{ name: 'end-timepicker' }}
                                    name="endTime"
                                    value={formik.values.endTime}
                                    onChange={handleEndTimeOnChange}
                                    error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                                    helperText={formik.touched.endTime && formik.errors.endTime}
                                />
                            </Grid.Row>
                            <StyledValidationMessage>{formik.touched.endTime && formik.errors.endTime}</StyledValidationMessage>
                            <Grid.Row style={{ marginTop: '5px' }} >
                                <label style={{ lineHeight: 2 }} >Duration: </label>
                                <StyledTextField
                                    type="text"
                                    disabled
                                    value={duration + ' minutes'}
                                    variant="outlined"
                                    size="small"
                                    margin="none"
                                    name="duration"
                                    id="duration"
                                    inputProps={{ style: { fontSize: 12, padding: 6 } }}
                                />
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: '5px' }} >
                                <label style={{ lineHeight: 2 }} >Date: </label>
                                <StyledDate
                                    name="startDate"
                                    type="date"
                                    inputProps={{ style: { fontSize: 12, padding: 6 } }}
                                    variant="outlined"
                                    size="small"
                                    margin="none"
                                    value={formik.values.startDate} 
                                    onChange={formik.handleChange}
                                    error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                />
                            </Grid.Row>
                            <StyledValidationMessage>{formik.touched.startDate && formik.errors.startDate}</StyledValidationMessage>
                        </StyledGrid>
                        <StyledGrid width={7}>
                            <StyledNotificationTitle>Notification</StyledNotificationTitle>
                            <Grid.Row style={{ marginTop: '5px', marginLeft: '11px', width: 'fit-content' }} >
                                <FormGroup style={{ display: 'flex', flexDirection: 'column' }} >
                                    <FormControlLabel
                                        style={{ marginBottom: '10px' }}
                                        control={
                                            <StyledCheckbox
                                                onClick={handleCheckBoxClick}
                                                value="15"
                                                checked={formik.values.notificationCheckbox.includes("15")}
                                            />
                                        }
                                        name="notificationCheckbox"
                                        onChange={formik.handleChange}
                                        label={<Typography style={{ fontSize: '12px' }}>15 mins</Typography>}
                                    />
                                    <FormControlLabel
                                        style={{ marginBottom: '10px' }}
                                        control={
                                            <StyledCheckbox
                                                onClick={handleCheckBoxClick}
                                                value="30"
                                                checked={formik.values.notificationCheckbox.includes("30")}
                                            />
                                        }
                                        name="notificationCheckbox"
                                        onChange={formik.handleChange}
                                        label={<Typography style={{ fontSize: '12px' }}>30 mins</Typography>}
                                    />
                                    <FormControlLabel
                                        style={{ marginBottom: '10px' }}
                                        control={
                                            <StyledCheckbox
                                                onClick={handleCheckBoxClick}
                                                value="45"
                                                checked={formik.values.notificationCheckbox.includes("45")}
                                            />
                                        }
                                        name="notificationCheckbox"
                                        onChange={formik.handleChange}
                                        label={<Typography style={{ fontSize: '12px' }}>45 mins</Typography>}
                                    />
                                    <FormControlLabel
                                        style={{ marginBottom: '10px' }}
                                        control={
                                            <StyledCheckbox
                                                onClick={handleCheckBoxClick}
                                                value="60"
                                                checked={formik.values.notificationCheckbox.includes("60")}
                                            />
                                        }
                                        name="notificationCheckbox"
                                        onChange={formik.handleChange}
                                        label={<Typography style={{ fontSize: '12px' }}>60 mins</Typography>}
                                    />
                                </FormGroup>
                            </Grid.Row>
                        </StyledGrid>
                    </StyledSectionContainer>
                    <StyledSectionContainer width={16} style={{ marginTop: '16px' }} >
                        <StyledRecurrence
                            control={
                                <StyledCheckbox
                                    onClick={handleCheckBoxClick}
                                />
                            }
                            name="recurrenceCheckbox"
                            onChange={handleRecurrencePatternChange }
                            label={<StyledRecurrenceLabel>Recurrence pattern</StyledRecurrenceLabel>}
                        />

                        <StyledGrid width={4} style={{ borderRight: '1px solid #a9a9a9', display: recurrencePattern ? "block" : "none" }} >
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="recurrence-type-group-label"
                                    defaultValue="daily"
                                    name="recurrence-type-radio-group"
                                    onChange={handleRecurrenceTypeChange}
                                >
                                    <FormControlLabel value="daily" control={<Radio size="small" color="primary" />} label={<StyledRecurrenceLabel>Daily</StyledRecurrenceLabel>} />
                                    <FormControlLabel value="weekly" control={<Radio size="small" color="primary" />} label={<StyledRecurrenceLabel>Weekly</StyledRecurrenceLabel>} />
                                    <FormControlLabel value="monthly" control={<Radio size="small" color="primary" />} label={<StyledRecurrenceLabel>Monthly</StyledRecurrenceLabel>} />
                                    <FormControlLabel value="yearly" control={<Radio size="small" color="primary" />} label={<StyledRecurrenceLabel>Yearly</StyledRecurrenceLabel>} />
                                </RadioGroup>
                            </FormControl>
                        </StyledGrid>
                        <StyledGrid width={12} style={{ display: recurrencePattern ? "block" : "none" }} >
                            <FormControl style={{ display: recurrenceType === "daily" ? "block" : "none"}} >
                                <RadioGroup
                                    aria-labelledby="daily-recurrence-group-label"
                                    defaultValue="first"
                                    name="daily-recurrence-radio-group"
                                    onChange={handleDailyRecurrenceOptionChange}
                                >
                                    <FormControlLabel value="first" control={<Radio size="small" color="primary" />} label={<StyledRecurrenceLabel>Every <StyledInput type="text" defaultValue="1" disabled={dailyRecurrenceOption === "first" ? false : true} /> day(s)</StyledRecurrenceLabel>}/>
                                    <FormControlLabel value="second" control={<Radio size="small" color="primary" />} label={<StyledRecurrenceLabel>Every weekday</StyledRecurrenceLabel>} />
                                </RadioGroup>
                            </FormControl>

                            <StyledRecurrenceLabel style={{ marginBottom: '20px', display: recurrenceType === "weekly" ? "block" : "none" }} >Recur every <StyledInput type="text" defaultValue="1" /> week(s) on:</StyledRecurrenceLabel>
                            <FormGroup style={{ display: recurrenceType === "weekly" ? "flex" : "none", flexDirection: 'row', flexWrap: 'wrap' }} >
                                <FormControlLabel
                                    style={{ width: '78px', marginTop: '13px' }}
                                    control={
                                        <StyledCheckbox
                                            value="15"
                                            checked
                                        />
                                    }
                                    name="weeklyCheckbox"
                                    label={<Typography style={{ fontSize: '11px' }}>Sunday</Typography>}
                                />
                                <FormControlLabel
                                    style={{ width: '78px', marginTop: '13px' }}
                                    control={
                                        <StyledCheckbox
                                            value="sun"
                                        />
                                    }
                                    name="weeklyCheckbox"
                                    label={<Typography style={{ fontSize: '11px' }}>Monday</Typography>}
                                />
                                <FormControlLabel
                                    style={{ width: '78px', marginTop: '13px' }}
                                    control={
                                        <StyledCheckbox
                                            value="mon"
                                        />
                                    }
                                    name="weeklyCheckbox"
                                    label={<Typography style={{ fontSize: '11px' }}>Tuesday</Typography>}
                                />
                                <FormControlLabel
                                    style={{ width: '78px', marginTop: '13px' }}
                                    control={
                                        <StyledCheckbox
                                            value="tue"
                                        />
                                    }
                                    name="weeklyCheckbox"
                                    label={<Typography style={{ fontSize: '11px' }}>Wednesday</Typography>}
                                />
                                <FormControlLabel
                                    style={{ width: '78px', marginTop: '11px' }}
                                    control={
                                        <StyledCheckbox
                                            value="thu"
                                        />
                                    }
                                    name="weeklyCheckbox"
                                    label={<Typography style={{ fontSize: '11px' }}>Friday</Typography>}
                                />
                                <FormControlLabel
                                    style={{ width: '78px', marginTop: '13px' }}
                                    control={
                                        <StyledCheckbox
                                            value="fri"
                                        />
                                    }
                                    name="weeklyCheckbox"
                                    label={<Typography style={{ fontSize: '11px' }}>Thursday</Typography>}
                                />
                                <FormControlLabel
                                    style={{ width: '78px', marginTop: '13px' }}
                                    control={
                                        <StyledCheckbox
                                            value="sat"
                                        />
                                    }
                                    name="weeklyCheckbox"
                                    label={<Typography style={{ fontSize: '11px' }}>Saturday</Typography>}
                                />
                            </FormGroup>

                            <FormControl style={{ display: recurrenceType === "monthly" ? "block" : "none" }}>
                                <RadioGroup
                                    aria-labelledby="monthly-recurrence-group-label"
                                    defaultValue="first"
                                    name="monthly-recurrence-radio-group"
                                    onChange={handleMonthlyRecurrenceOptionChange}
                                >
                                    <FormControlLabel value="first" control={<Radio size="small" color="primary" />} label={<StyledRecurrenceLabel>Day <StyledInput type="text" defaultValue="1" disabled={monthlyRecurrenceOption === "first" ? false : true} /> of every <StyledInput type="text" defaultValue="1" disabled={monthlyRecurrenceOption === "first" ? false : true} /> month(s)</StyledRecurrenceLabel>}/>
                                    <FormControlLabel value="second" control={<Radio size="small" color="primary" />}
                                        label={
                                            <StyledRecurrenceLabel>The
                                                <StyledOrdinalDropdown
                                                    id="monthly-week-select"
                                                    defaultValue="first"
                                                    disabled={monthlyRecurrenceOption === "second" ? false : true}
                                                >
                                                    <option value="first">First</option>
                                                    <option value="second">Second</option>
                                                    <option value="third">Third</option>
                                                    <option value="fourth">Fourth</option>
                                                    <option value="last">Last</option>
                                                </StyledOrdinalDropdown>
                                                <StyledWeekendDropdown
                                                    id="monthly-day-of-week-select"
                                                    defaultValue="sun"
                                                    disabled={monthlyRecurrenceOption === "second" ? false : true} 
                                                >
                                                    <option value="sun">Sunday</option>
                                                    <option value="mon">Monday</option>
                                                    <option value="tue">Tuesday</option>
                                                    <option value="wed">Wednesday</option>
                                                    <option value="thu">Thursday</option>
                                                    <option value="fri">Friday</option>
                                                    <option value="sat">Saturday</option>
                                                    <option value="day">Day</option>
                                                    <option value="weekday">Weekday</option>
                                                    <option value="weekendDay">Weekend Day</option>
                                                </StyledWeekendDropdown>
                                                of every <StyledInput type="text" defaultValue="1" disabled={monthlyRecurrenceOption === "second" ? false : true} /> month(s)
                                            </StyledRecurrenceLabel>
                                        }
                                        
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl style={{ display: recurrenceType === "yearly" ? "block" : "none" }}>
                                <StyledRecurrenceLabel style={{ marginBottom: '20px' }} >Recur every <StyledInput type="text" defaultValue="1" /> year(s)</StyledRecurrenceLabel>
                                <RadioGroup
                                    aria-labelledby="yearly-recurrence-group-label"
                                    defaultValue="first"
                                    name="yearly-recurrence-radio-group"
                                    onChange={handleYearlyRecurrenceOptionChange}
                                >
                                    <FormControlLabel value="first" control={<Radio size="small" color="primary" />}
                                        label={
                                            <StyledRecurrenceLabel>On:
                                                <StyledMonthDropdown
                                                    id="yearly-month-select"
                                                    defaultValue="jan"
                                                    disabled={yearlyRecurrenceOption === "first" ? false : true} 
                                                >
                                                    <option value="jan">January</option>
                                                    <option value="feb">February</option>
                                                    <option value="mar">March</option>
                                                    <option value="apr">April</option>
                                                    <option value="may">May</option>
                                                    <option value="jun">June</option>
                                                    <option value="jul">July</option>
                                                    <option value="aug">August</option>
                                                    <option value="sep">September</option>
                                                    <option value="oct">October</option>
                                                    <option value="nov">November</option>
                                                    <option value="dec">December</option>
                                                </StyledMonthDropdown>
                                                <StyledInput type="text" defaultValue="1" disabled={yearlyRecurrenceOption === "first" ? false : true} />
                                            </StyledRecurrenceLabel>
                                        }
                                    />
                                    <FormControlLabel value="second" control={<Radio size="small" color="primary" />}
                                        label={
                                            <StyledRecurrenceLabel>On the:
                                                <StyledOrdinalDropdown
                                                    id="yearly-week-select"
                                                    defaultValue="first"
                                                    disabled={yearlyRecurrenceOption === "second" ? false : true} 
                                                >
                                                    <option value="first">First</option>
                                                    <option value="second">Second</option>
                                                    <option value="third">Third</option>
                                                    <option value="fourth">Fourth</option>
                                                    <option value="last">Last</option>
                                                </StyledOrdinalDropdown>
                                                <StyledWeekendDropdown
                                                    id="yearly-day-of-week-select"
                                                    defaultValue="sun"
                                                    disabled={yearlyRecurrenceOption === "second" ? false : true} 
                                                >
                                                    <option value="sun">Sunday</option>
                                                    <option value="mon">Monday</option>
                                                    <option value="tue">Tuesday</option>
                                                    <option value="wed">Wednesday</option>
                                                    <option value="thu">Thursday</option>
                                                    <option value="fri">Friday</option>
                                                    <option value="sat">Saturday</option>
                                                    <option value="day">Day</option>
                                                    <option value="weekday">Weekday</option>
                                                    <option value="weekendDay">Weekend Day</option>
                                                </StyledWeekendDropdown>
                                                of
                                                <StyledMonthDropdown
                                                    id="yearly-second-month-select"
                                                    defaultValue="jan"
                                                    disabled={yearlyRecurrenceOption === "second" ? false : true} 
                                                >
                                                    <option value="jan">January</option>
                                                    <option value="feb">February</option>
                                                    <option value="mar">March</option>
                                                    <option value="apr">April</option>
                                                    <option value="may">May</option>
                                                    <option value="jun">June</option>
                                                    <option value="jul">July</option>
                                                    <option value="aug">August</option>
                                                    <option value="sep">September</option>
                                                    <option value="oct">October</option>
                                                    <option value="nov">November</option>
                                                    <option value="dec">December</option>
                                                </StyledMonthDropdown>
                                            </StyledRecurrenceLabel>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </StyledGrid>
                        
                    </StyledSectionContainer>
                    <Grid.Row className="button-group" style={{ justifyContent: 'flex-end', paddingRight: '9px' }} >
                        <Button className="tiny ui primary button" type="submit">
                            Save
                        </Button>
                        <Button className="tiny ui button" onClick={dateModalStore.closeModal} type="button">
                            Cancel
                        </Button>
                    </Grid.Row>
                </Grid>
            </form>
        </>
    );
}

export default observer(DateDetails);