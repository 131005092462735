import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { Employee } from '../models/employee';
import { DailyReport, DailyReportFilterValues } from '../models/employeeDailyReport';
import { OptionSelect } from '../models/option';

export default class DailyReportStore {
    dailyReports: DailyReport[] = [];
    dailyReportsTotal: number = 0;
    isDailyReportListLoading: boolean = false;
    isInitialLoading: boolean = false;

    userEmployees: Employee[] = [];
    isLoadingUserEmployees: boolean = false;
    currentUserIds: MultiValue<{ value: string }> = [];

    constructor() {
        makeAutoObservable(this);
    }

    get userOptionValues(): OptionSelect[] {
        if (this.userEmployees.length) {
            let sortedByName = this.userEmployees.slice(0);
            return sortedByName
                .sort(function (a, b) {
                    var x = a.firstName.toLowerCase();
                    var y = b.firstName.toLowerCase();
                    return x < y ? -1 : x > y ? 1 : 0;
                })
                .map(e => { return { value: e.id, label: e.name } as OptionSelect });
        } else {
            return [];
        }
    }

    filterDailyReports = async (filters: DailyReportFilterValues, isInitial?: boolean) => {
        if (isInitial) this.isInitialLoading = true;
        this.isDailyReportListLoading = true;

        try {
            const { total, data } = await agent.DailyReports.filter(filters);
            runInAction(() => {
                this.dailyReports = data;
                this.dailyReportsTotal = total;
                this.isDailyReportListLoading = false;
                this.isInitialLoading = false;

            });
        } catch (e) {
            console.log(e);
            this.isDailyReportListLoading = false;
            this.isInitialLoading = false;
        }
    }


    loadUserEmployees = async (search: string) => {
        this.isLoadingUserEmployees = true;

        try {
            const { total, employees } = await agent.Employees.filterEmployeesSelectedDetails({
                searchKeyword: search,
                pageSize: 0,
                pageNumber: 0
            });

            runInAction(() => {
                this.userEmployees = employees;
                this.isLoadingUserEmployees = false;
            });
        } catch (error) {
            this.isLoadingUserEmployees = false;
        }
    }

    setCurrentUserIds = (userIds: MultiValue<{ value: string }>) => {
        this.currentUserIds = userIds;
    }

    reset = () => {
        this.dailyReports = [];
        this.dailyReportsTotal = 0;
        this.userEmployees = [];
        this.currentUserIds = [];
    }
}