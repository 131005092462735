import { Button, IconButton, InputBase, makeStyles, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { MdClear } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useStore } from '../../../app/stores/store';

const useStyle = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1, 1, 1, 2),
        margin: theme.spacing(1),
    },
    input: {
        fontSize: '.9rem'
    },
    btnConfirm: {
        background: '#0079bf',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#026aa7'
        }
    },
    confirm: {
        margin: theme.spacing(0, 1, 1, 1),
    }
}));

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    listId: string;
};

const InputCard = ({ setOpen, listId }: Props) => {
    const classes = useStyle();
    const { cardStore, userStore } = useStore();
    const { add } = cardStore;
    const { user } = userStore;

    const [title, setTitle] = useState<string>('');

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    }

    const handleBtnConfirm = async () => {
        const trimmedTitle = title.trim();

        if (trimmedTitle.length < 1) {
            setOpen(false);
            toast.error('Task title should not be empty');
        }
        else if (trimmedTitle.length >= 512) {
            toast.error('Task title should not exceed 512 characters');
        } else {
            await add({
                title: trimmedTitle,
                description: '',
                cardGroupId: listId,
                createdUserId: user!.id
            });
            setTitle('');
            setOpen(false);
        }
    }

    const handleClear = () => {
        setTitle('');
        setOpen(false);
    }

    const handleOnBlur = () => {
        const trimmedTitle = title.trim();
        if (trimmedTitle.length < 1) {
            setTitle('');
            setOpen(false);
        }
    }

    return (
        <div>
            <div>
                <Paper className={classes.card}>
                    <InputBase
                        multiline
                        fullWidth
                        inputProps={{ className: classes.input }}
                        placeholder='Enter a title of this task...'
                        value={title}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                </Paper>
            </div>
            <div className={classes.confirm}>
                <Button className={classes.btnConfirm} onMouseDown={handleBtnConfirm}>
                    Add Task
                </Button>
                <IconButton onClick={handleClear}>
                    <MdClear />
                </IconButton>
            </div>
        </div>
    );
}

export default InputCard;