import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { Button, Divider, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useStore } from '../../../app/stores/store';

//#region Styling
const BodyContainer = styled.div`
    padding: 0 12px 12px;
    display: flex;
    min-height: 500px;
    max-height: 700px;
    flex-direction: column;
    justify-content: space-between;
`;

const HeaderContainer = styled.div`
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    padding:8px;
    & h4{
        margin:0 !important;
    }
`;

const ContentWrapper = styled.div`
 && {
        border-radius: 3px;
        box-shadow: 0 8px 16px -4px #091e4240, 0 0 0 1px #091e4214;
        width: 304px;
        min-height: 500px;
        max-height: 700px;
    }
`;

const ButtonContainer = styled.div`
    && {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
`;

const selectStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '150px'
    }),
    menu: (provided, state) => ({
        ...provided,
        overflowY: 'auto',
        maxHeight: '300px'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        display:'none'
    }),
  
}

//#endregion

interface Props {
    workSpaceId: string;
    refreshMainTable: () => void;
}

const WorkSpaceMemberForm = ({ workSpaceId, refreshMainTable }: Props) => {
    const [selectedOption, setSelectedOption] = useState<MultiValue<{ value: string }>>([]);
    const { workSpaceUserStore, popOverStore } = useStore();
    const { loadMembers, loadNonMembers, memberOptionValues, addMultiple, isLoadingClientEmployees } = workSpaceUserStore;

    const handleSubmit = async (values: MultiValue<{ value: string; }>, { setErrors, resetForm, setSubmitting }) => {
        let userIds = values.map(v => v.value);
        await addMultiple(workSpaceId, userIds)
            .then(() => {
                toast.success('Added member/s successfully.');
                popOverStore.closePopover();
                refreshMainTable();
            }, reason => {
                console.log(reason);
            });
        setSubmitting(false);
    }

    useEffect(() => {
        loadMembers('');
        loadNonMembers(workSpaceId);
    }, [])

    return (
        <ContentWrapper>
            <HeaderContainer>
                <Header as='h4'>
                    Add Members
                </Header>
                <Divider />
            </HeaderContainer>
            <Formik
                enableReinitialize
                initialValues={selectedOption}
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                    userIds: Yup.array().min(1, 'Please select atleast one.').required()
                })}>
                {
                    ({ handleSubmit, isSubmitting, dirty, isValid, values }) => (
                        <Form className='ui form' onSubmit={handleSubmit} autoComplete='off'>
                            <BodyContainer>
                                <Select
                                    styles={selectStyles}
                                    name='userIds'
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    options={memberOptionValues}
                                    isMulti={true}
                                    menuIsOpen={true}
                                    placeholder='Select users...'
                                    noOptionsMessage={() => 'No user available'}
                                    isLoading={isLoadingClientEmployees}
                                />
                                <ButtonContainer>
                                    <Button
                                        size='small'
                                        compact
                                        color='grey'
                                        onClick={() => popOverStore.closePopover()}
                                    >Cancel</Button>
                                    <Button
                                        type='submit'
                                        loading={isSubmitting}
                                        disabled={!isValid || isSubmitting || !selectedOption.length}
                                        size='small'
                                        compact
                                        color='blue'
                                    >Save</Button>
                                </ButtonContainer>
                            </BodyContainer>
                        </Form>
                    )
                }
            </Formik>
        </ContentWrapper>
    );
}

export default observer(WorkSpaceMemberForm);