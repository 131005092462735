import React, { useEffect, useState } from 'react';
import { Image, ImageProps } from 'semantic-ui-react';
import { useStore } from '../../stores/store';

interface Props extends ImageProps {
    userId: string;
    hasPhoto: boolean;
}

const fileToDataUri = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        resolve(event.target!.result)
    };
    reader.readAsDataURL(file);
})

const AvatarImage = ({ userId, hasPhoto, ...rest }: Props) => {
    const { userStore, mediaStore } = useStore();
    const [dataUri, setDataUri] = useState('');
    useEffect(() => {
        let unmounted = false;

        if (hasPhoto) {
            try {
                mediaStore.getProfilePicture(userId).then(v => {
                    if (v != undefined && !unmounted)
                        setDataUri(v.url)
                });
            } catch (e) {
                if (!unmounted)
                    setDataUri('/assets/default-profile-picture.svg');
            }
        } 

        return () => { unmounted = true };
    }, [])

    return (
        <Image
            {...rest}
            src={dataUri || '/assets/default-profile-picture.svg'}
            avatar />
    );
}

export default AvatarImage;