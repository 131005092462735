import { IconButton } from '@material-ui/core';
import { DH_CHECK_P_NOT_PRIME } from 'constants';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BsCardHeading, BsListUl, BsTextParagraph } from 'react-icons/bs';
import { MdClear } from 'react-icons/md';
import { Button, Divider, Grid, Header, Item } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CardDate } from '../../../app/models/cardDate';
import { useStore } from '../../../app/stores/store';
import CardActivityList from '../card-activities/CardActivityList';
import AttachmentList from '../card-attachments/AttachmentList';
import Checklist from '../card-checklist/Checklist';
import CommentList from '../card-comments/CommentList';
import DateDetails from '../card-dates/DateDetails';
import CardMembersContainer from '../card-members/CardMemberContainer';
import AttachmentMenu from '../card-menus/AttachmentMenu';
import ChecklistMenu from '../card-menus/ChecklistMenu';
import DateMenu from '../card-menus/DateMenu';
import MembersMenu from '../card-menus/MembersMenu';
import CardPlaceholder from './CardPlaceholder';
import DescriptionInput from './DescriptionInput';
import TitleInput from './TitleInput';

//#region Styling

const TitleIcon = styled(BsCardHeading)`
    margin: 2px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

const DescriptionIcon = styled(BsTextParagraph)`
    margin: 4px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

const ActivityIcon = styled(BsListUl)`
    margin: 4px 16px 0 0;
    font-size: 20px;
    color:#42526e;
`;

const ItemContent = styled(Item.Content)`
    &&{
        margin: 0 0 0 0 !important;
    }
`;
const ItemInListContent = styled(Item.Content)`
    &&{
        margin: -15px 0 0 47px !important;
    }
`;

const StyledItem = styled(Item)`
    &&{
        margin:16px 0 !important;
    }
`;
const StyledMember = styled(Item)`
    &&{
        margin:0 0 16px 0 !important;
    }
`;

const Menu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;

    & i{
        background:none !important;
    }
`;

const ScheduleMenu = styled(Button)`
    background:#091e4214 !important;
    margin-bottom: 8px !important;
    padding: 5px 3px !important;
    font-weight: normal !important;
    font-size: 13px;

    & i{
        background:none !important;
    }
`;

const ScheduleCloseIconStyled = styled(IconButton)`
    && {
        right: -18px;
        position: absolute;
        top: -12px;
    }
`;

//#endregion

const _swal = withReactContent(Swal);


interface Props {
    id: string;
    cardGroupName: string
}

const CardDetails = ({ id, cardGroupName }: Props) => {
    const { cardStore, boardStore, cardMemberStore, cardDateStore, dateModalStore } = useStore();
    const { cardMembers } = cardMemberStore;
    const { openModal } = dateModalStore;
    const { isLoading, card, deleteCard } = cardStore;
    const { activeBoard } = boardStore;
    const { getSchedule, deleteSchedule, cardDate } = cardDateStore;
    const [schedule, setSchedule] = useState<CardDate | undefined>(undefined);

    useEffect(() => {
        cardStore.loadCard(id);
        if (id) getSchedule(id);
        return () => {
            cardStore.stopListening(id);
            cardStore.reset();
        }
    }, [cardStore, id]);

    useEffect(() => {
        runInAction(async () => {
            setSchedule(cardDate);
        });
    }, [cardDate]);

    const handleOnDelete = () => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Task?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                deleteCard(card!.id, card!.cardGroupId);
            }
        });
    };

    const handleOnScheduleIndicatorClick = () => {
        openModal(<DateDetails cardId={card!.id} />)
    };

    const formattedScheduleDetails = () => {
        let date = new Date(schedule!.startDate + 'Z'),
            formattedDate = moment(date).format('MMM D'),
            startTime = new Date(schedule!.startTime + 'Z'),
            formattedStartTime = moment(startTime).format('hh:mm a');

        return {
            formattedDate,
            formattedStartTime
        };
    };

    const handleScheduleCloseIconClick = (e) => {
        _swal.fire({
            title: 'Remove?',
            text: "Are you sure you want to remove this schedule?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                deleteSchedule(card!.id);
            }
        });
    }

    if (isLoading || !card) return <CardPlaceholder />

    return (
        <>
            <Grid>
                <Grid.Column width={15}>
                    <Item.Group>
                        <Item>
                            <TitleIcon />
                            <ItemContent>
                                <TitleInput card={card!} />
                            </ItemContent>
                        </Item>

                        <Item>
                            <ItemInListContent> <span style={{ color: 'rgba(0, 0, 0, .6)' }} >in list </span><span style={{ color: 'rgba(0, 0, 0, .6)', textDecoration: 'underline' }} >{cardGroupName}</span></ItemInListContent>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column style={{ paddingTop: 0 }} width={12}>
                    <Grid.Row>

                        {schedule && (
                            <span style={{ width: 178, display: 'inline-block', marginLeft: '47px', position: 'relative' }} >
                                <ScheduleCloseIconStyled size="small" onClick={ handleScheduleCloseIconClick } >
                                    <MdClear />
                                </ScheduleCloseIconStyled>
                                <ScheduleMenu onClick={handleOnScheduleIndicatorClick} fluid>
                                    <i className="long alternate left calendar icon"></i> {formattedScheduleDetails()?.formattedDate + " at " + formattedScheduleDetails()?.formattedStartTime} &nbsp;<i className="down angle icon"></i>
                                </ScheduleMenu>
                            </span>)}

                        <Item.Group style={{ marginLeft: '35px', color: 'rgba(0, 0, 0, .6)', fontWeight: 'bold' }}>
                            {cardMembers.length == 0 ? null : <span style={{ marginLeft: '15px' }}>Members</span>} 
                            <StyledMember>
                                <ItemContent>
                                    <CardMembersContainer cardId={card?.id!} createdUserId={card?.createdUserId} />
                                </ItemContent>
                            </StyledMember>
                        </Item.Group>
                        <Item.Group>
                            <StyledItem>
                                <DescriptionIcon />
                                <ItemContent>
                                    <DescriptionInput card={card!} />
                                </ItemContent>
                            </StyledItem>
                        </Item.Group>
                        <Item.Group style={{ marginTop: '32px', padding: '1em 0' }}>
                            {card?.attachments && card?.attachments.length != 0 && (<AttachmentList attachments={card!.attachments!} cardId={id} />)}
                            {card?.checklists && card.checklists.map(c => (
                                <Checklist key={c.id} checklist={c} />
                            ))}
                            <StyledItem>
                                <ActivityIcon />
                                <ItemContent>
                                    <CardActivityList />
                                </ItemContent>
                            </StyledItem>
                            <StyledItem>
                                <ItemContent style={{ display: 'flex', flexDirection: 'column' }}>
                                    <CommentList card={card!} />
                                </ItemContent>
                            </StyledItem>
                        </Item.Group>

                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4} style={{ padding: '1em 1em 1em 0' }}>
                    <Grid.Row>
                        <Header as='h4' style={{ fontSize: '14px' }}>
                            Add to task
                        </Header>
                        <Divider />
                        <MembersMenu cardId={card?.id!} workspaceId={activeBoard?.workSpaceId!} />
                        <ChecklistMenu cardId={card?.id!} />
                        <DateMenu cardId={card?.id!} />
                        <AttachmentMenu cardId={card?.id!} />
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: '24px' }}>
                        <Header as='h4' style={{ fontSize: '14px' }}>
                            Actions
                        </Header>
                        <Divider />
                        <Menu icon='long arrow alternate right' onClick={handleOnDelete} labelPosition='left' content='Delete' fluid />
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        </>
    );
}

export default observer(CardDetails);