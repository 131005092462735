import React, { useEffect, useState } from 'react';
import { Button, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { CardChecklist, CardChecklistFormValues } from '../../../app/models/cardChecklist';
import { useStore } from '../../../app/stores/store';
import { IconButton } from '@material-ui/core';
import { MdClear } from 'react-icons/md';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

//#region Styling

const ComponentWrapper = styled.div`
     && {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
     }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: nowrap;
    width:100%;
`;

const StyledTextArea = styled(TextareaAutosize)`
    && {
        width:100%;
        overflow: hidden;
        overflow-wrap: break-word;
        background: #0000;
        border-radius: 3px;
        line-height: 24px;
        min-height: 24px;
        padding: 4px 8px;
        resize: none;
        background-color: #fff;
        box-shadow: inset 0 0 0 2px #0079bf;
        border: none;
        outline:none;
    }
`;
//#endregion

interface Props {
    checklist: CardChecklist;
}

const _swal = withReactContent(Swal);

const ChecklistTitleInput = ({ checklist }: Props) => {
    const minRows = 1;
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState(checklist.title);
    const { cardChecklistStore } = useStore();
    const { update, deleteChecklist } = cardChecklistStore;

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTitle(event.target.value);
    }

    const handleOnSave = async () => {
        if (title && title.length > 0 && title.length <= 512) {
            checklist.title = title;
            update(new CardChecklistFormValues(checklist));
            setOpen(false);
        } else {
            setTitle(checklist.title);
            toast.error('Title should not be empty or exceed 512 characters');
            setOpen(false);
        }
    }

    const handleClear = () => {
        setTitle(checklist.title);
        setOpen(false);
    }

    const handleDelete = () => {
        _swal.fire({
            title: 'Delete?',
            text: "Are you sure you want to delete this Checklist?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                _swal.disableButtons();
                deleteChecklist(checklist.id);
            }
        });
    }

    useEffect(() => {
        setTitle(checklist.title);
    }, [checklist.title]);

    return (
        <ComponentWrapper>
            {open ? (
                <>
                    <StyledTextArea
                        autoFocus
                        value={title}
                        onChange={handleOnChange}
                        minRows={minRows}
                        placeholder='Enter Checklist title...'
                    />
                    <ButtonContainer>
                        <Button compact color='blue' size='small' onMouseDown={handleOnSave}>
                            Save
                        </Button>
                        <IconButton onClick={handleClear}>
                            <MdClear />
                        </IconButton>
                    </ButtonContainer>
                </>
            ) : (
                <HeaderContainer>
                    <Header
                        as='h4'
                        style={{ whiteSpace: 'pre-wrap', flexGrow: 1 }}
                        onClick={() => setOpen(true)}
                    >{title}</Header>
                    <Button
                        compact
                        size='small'
                        onClick={handleDelete}
                    >Delete</Button>
                </HeaderContainer>
            )}
        </ComponentWrapper>
    );
}

export default ChecklistTitleInput;