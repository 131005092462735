import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FaPencilAlt, FaRegEye, FaTrashAlt } from 'react-icons/fa';
import { MdGroupAdd } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import TextOverflow from '../../../app/common/text/TextOverflow';
import { FilterValues } from '../../../app/models/common/filterValues';
import { WorkSpace } from '../../../app/models/workSpace';
import { useStore } from '../../../app/stores/store';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const swal = withReactContent(Swal);
const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledColumn = styled(Grid.Column)`
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important;
`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;
const tableTheme = createMuiTheme({
    overrides: {
        MuiTableRow: {
            hover: {
                "&:hover": {
                    backgroundColor: " #e0effd !important"
                }
            }
        }
    }
});
const WorkSpaceList = () => {
    const { workSpaceStore } = useStore();
    const { filterWorkSpaces, isWorkSpaceListLoading, workSpaces,
        workSpacesTotal, deleteWorkSpace } = workSpaceStore;
    const history = useHistory();

    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 10,
        pageNumber: 1,
        searchKeyword: ""
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }
    const handleOnRowClick = (event,rowData) => {
        history.push(`/workspaces/board/${rowData.id}`)
    }
    const handleSearch = (searchText: string) => {
        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText });
        }
    }

    useEffect(() => {
        filterWorkSpaces(filterValues);
    }, [filterValues, filterWorkSpaces])

    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    <Grid>
                        <Grid.Column width={8}>
                            Workspaces
                        </Grid.Column>
                        <StyledColumn width={8}>
                            <Button icon='add' color='blue' as={Link} to='/workspaces/add' content='Add a New Workspace' />
                        </StyledColumn>
                    </Grid>
                </StyledHeader>
                <ThemeProvider theme={tableTheme}>
                    <PaginatedTable<WorkSpace>
                        columns={[
                            { title: 'Workspace Name', field: 'name' },
                            {
                                title: 'Description',
                                field: 'description'
                            },
                        ]}
                        onRowClick={handleOnRowClick}
                        isLoading={isWorkSpaceListLoading}
                        data={workSpaces!}
                        total={workSpacesTotal}
                        onChangePage={handleOnChangePage}
                        pageSize={filterValues.pageSize!}
                        pageNumber={filterValues.pageNumber}
                        sorting={false}
                        draggable={false}
                        onSearchChange={handleSearch}
                        toolbar={true}
                        search={true}
                        actions={[
                            //rowData => ({
                            //    icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                            //    tooltip: 'View Projects',
                            //    onClick: e => {
                            //        history.push(`/workspaces/${rowData.id}`)
                            //    },
                            //}),
                            rowData => ({
                                icon: () => <MdGroupAdd style={{ color: '#97a4b5', fontSize: '25px' }} />,
                                tooltip: 'Add Member',
                                onClick: e => {
                                    history.push(`/workspaces/workspace-members/${rowData.id}`)
                                },
                            }),
                            rowData => ({
                                icon: () => <FaPencilAlt style={{ color: '#97a4b5', fontSize: '16px' }} />,
                                tooltip: 'Edit',
                                onClick: e => {
                                    history.push(`/workspaces/edit/${rowData.id}`)
                                },
                            }),
                            rowData => ({
                                icon: () => <FaTrashAlt style={{ color: '#97a4b5', fontSize: '16px' }} />,
                                tooltip: 'Delete',
                                onClick: e => {
                                    swal.fire({
                                        title: 'Delete?',
                                        text: "Are you sure you want to delete this Workspace?",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: 'No',
                                        confirmButtonText: 'Yes'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            deleteWorkSpace(rowData.id, filterValues);
                                        }
                                    });
                                },
                            }),
                        ]}
                    />
                </ThemeProvider>
              
            </StyledContainer>
        </>

    );
};

export default observer(WorkSpaceList);