import { makeStyles, Paper } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { CardGroup } from '../../../app/models/cardGroup';
import Card from '../cards/Card';
import CardListTitle from './CardListTitle';
import InputCardContainer from './InputCardContainer';

const useStyle = makeStyles((theme) => ({
    root: {
        width: '272px',
        backgroundColor: '#ebecf0',
        margin: theme.spacing(1),
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'normal',
        position: 'relative'
    },
    cardContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: '1 auto',
    }
}));

const CardListWrapper = styled.div`
    && {
    box-sizing: border-box;
    display: inline-block;
    height: 98%;
    margin: 0 4px;
    vertical-align: top;
    white-space: nowrap;
    width: 272px;
    }
`;

interface Props {
    list: CardGroup;
    index: number;
}

const CardList = ({ list, index }: Props) => {
    const classes = useStyle();

    return (
        <>
            <Draggable draggableId={list.id} index={index}>
                {(provided) => (
                    <CardListWrapper ref={provided.innerRef} {...provided.draggableProps}>
                        <Paper className={classes.root} elevation={0} {...provided.dragHandleProps}>
                            <CardListTitle title={list.title} listId={list.id} boardId={list.boardId} />
                            <Droppable droppableId={list.id}>
                                {(provided) => (
                                    <div ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={classes.cardContainer}>
                                        {list.order && list.order.map((id, index) => {
                                            if (list.cards) {
                                                let card = list?.cards?.find(c => c.id === id);
                                                if (card)
                                                    return (<Card card={card!} key={id} index={index} cardGroupName={ list.title } />)
                                            }
                                        })}
                                        <div style={{ minHeight: '1px' }}>
                                            {provided.placeholder}
                                        </div>
                                        <InputCardContainer listId={list.id} />
                                    </div>
                                )}
                            </Droppable>
                        </Paper>
                    </CardListWrapper>
                )}
            </Draggable>
        </>
    );
}

export default observer(CardList);