import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import DateHelper from '../helpers/dateHelper';
import { Message, MessageAttachment, PostMessage, PostMessageAttachment } from '../models/message';
import { store } from './store';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';


export default class MessageStore {
    isMessageSending = false;
    isLoading = false;
    messages: Message[] = [];
    temporaryMessage?: Message;
    isMinimize: boolean = false;
    files: any[] = [];
    temporaryAttachment?: MessageAttachment;
    temporaryAttachments: MessageAttachment[] = [];
    temporaryMessages: PostMessageAttachment [] = [];
    constructor() {
        makeAutoObservable(this);
    }


    postMessage = async (message: PostMessage) => {
        this.isMessageSending = true;
        try {
            let data = await agent.Messages.add(message);
            runInAction(() => {
                this.isMessageSending = false;
            });
            return Promise.resolve(data);
        } catch (e) {
            console.error(e);
            this.isMessageSending = false;
            return Promise.reject(e);
        }
    }

    addMessageWithAttachment = async (message: PostMessageAttachment) => {
        let loadingId = uuidv4();
        message.loadingId = loadingId;
        this.isMessageSending = true;
        if (message.files.length > 0) {
            for (var i = 0; i < message.files.length; i++) {
                this.temporaryAttachments = [
                    ...this.temporaryAttachments,
                    { ...this.temporaryAttachment!, fileName: message.files[i].name, size: message.files[i].size }
                ];
            }
      
        }
        this.messages = [...this.messages,
        {
            ...this.temporaryMessage!,
            isMessageUploading: true,
            attachments: message.files.length > 0 ? this.temporaryAttachments : [],
            isMyMessage: true,
            content: message.content,
            loadingId: loadingId,
            localTime: new Date().toLocaleString(),
            }];
        this.temporaryAttachments = [];
        try {
            let data = await agent.Messages.addMessageAttachment(message);
            runInAction(() => {
                this.isMessageSending = false;
            
            });
            return Promise.resolve(data);
        } catch (e) {
            console.error(e);
            this.isMessageSending = false;
            return Promise.reject(e);
        }
    }

    get messagesWithDates() {
        const _dateHelper = new DateHelper();
        let messages = [...this.messages];
        for (var i = 0; i < messages.length; i++) {
            let isSystemGeneratedMessage = messages[i].content?.includes('[[SystemGenerated]]');
            let sendDateAndTime = new Date(messages[i].localTime);
            if (i == 0) {
                if (_dateHelper.toReadableDate(sendDateAndTime) == _dateHelper.toReadableDate(new Date())) {
                    messages[i].sentDate = "Today"
                } else {
                    messages[i].sentDate = _dateHelper.toReadableDate(sendDateAndTime)
                }
                messages[i].sentTime = _dateHelper.toLocalTimeString(sendDateAndTime)
            }
            if (i > 0) {
                if (_dateHelper.toReadableDate(new Date(messages[i].localTime)) == _dateHelper.toReadableDate(new Date(messages[i - 1].localTime))) {
                    messages[i].sentDate = "";

                } else {
                    if (_dateHelper.toReadableDate(sendDateAndTime) == _dateHelper.toReadableDate(new Date())) {
                        messages[i].sentDate = "Today"
                    } else {
                        messages[i].sentDate = _dateHelper.toReadableDate(sendDateAndTime)
                    }

                }
              
                let lastMessageTime = _dateHelper.toLocalTimeString(new Date(messages[i - 1].localTime));
                let currentMessageTime = _dateHelper.toLocalTimeString(new Date(messages[i].localTime))

                if (!isSystemGeneratedMessage && (lastMessageTime == currentMessageTime) && (messages[i].senderId == messages[i - 1].senderId)) {
                    messages[i - 1].sentTime = "";
                    messages[i].sentTime = _dateHelper.toLocalTimeString(sendDateAndTime)
                }

                else {
                    messages[i].sentTime = _dateHelper.toLocalTimeString(sendDateAndTime)
                }
            }
            

        }
        return messages;
    }

    loadMessage = async (conversationId: string) => {
        try {
            const messages = await agent.Messages.getAll(conversationId);
            runInAction(() => {
                this.messages = messages;
            });
        } catch (error) {
            console.error(error);
        }
    }

     bytesToSize = (bytes, decimals = 2) => {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    updateIsRead = async (conversationId: string, userId: string) => {
        try {
            const id = await agent.Conversations.updateReadReceipt(conversationId);
            store.conversationStore.conversations = store.conversationStore.conversations.map(c => {
                if (c.id === conversationId)
                    return {
                        ...c,
                        readReceiptUserIds: [...c.readReceiptUserIds, userId]
                    }
                return c;
            });
        } catch (error) {
            console.error(error);
        }
    }

    setMessages = (messages: Message[]) => {
        this.messages = messages;
    }

    setIsMinimize = (value: boolean) => {
        this.isMinimize = value;
    }

    setFiles = (files: File[]) => {
        this.files = files;
    }
}