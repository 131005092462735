import React from 'react';
import { Menu, Tab } from 'semantic-ui-react';
import { Employee } from '../../../app/models/employee';
import EmployeeGeneralDetails from './EmployeeGeneralDetails';
import EmployeeAdditionalDetails from './EmployeeAdditionalDetails';
import EmployeeSchedule from './EmployeeSchedule';
import EmployeeDailyReports from './EmployeeDailyReports';
import EmployeeDtr from './EmployeeDtr';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const StyledMenuItem = styled(Menu.Item)`
    margin-bottom:0 !important;
    background-color: #f3f9ff !important;
    color: #6a7e99 !important;

    &:before{
        top: 25% !important;
        height: 50% !important;
        background-color: #6a7e99 !important;
    }

    &:hover, &.active{
        background-color: #1592ff !important ;
        color: #fff !important ;
    }
`;

const StyledTab = styled(Tab)`
    border: none !important;

    & .menu{
        border: none !important;
        box-shadow: none !important;
        }

    & .segment { border: none !important; }

`;


interface Props {
    employee: Employee;
}

const EmployeeDetails = ({ employee }: Props) => {

        const panes = [
            {
                menuItem: <StyledMenuItem key='General'>General</StyledMenuItem>,
                render: () => <EmployeeGeneralDetails employee={employee} />
            },
            {
                menuItem: <StyledMenuItem key='Additional'>Additional Details</StyledMenuItem>,
                render: () => <EmployeeAdditionalDetails />
            },
            {
                menuItem: <StyledMenuItem key='Schedule'>Schedule</StyledMenuItem>,
                render: () => <EmployeeSchedule />
            },
            {
                menuItem: <StyledMenuItem key='DTR'>DTR</StyledMenuItem>,
                render: () => <EmployeeDtr employeeId={employee.id} />
            },
            //{
            //    menuItem: <StyledMenuItem key='Daily'>Daily Report</StyledMenuItem>,
            //    render: () => <EmployeeDailyReports employeeId={employee.id} />
            //},
        ];

    return (
        <StyledTab
            menu={{ attached: false,}}
            panes={panes}
        />
    );
}

export default observer(EmployeeDetails);