import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import ModalStore from './modalStore';
import EmployeeStore from './employeeStore';
import SubClientStore from './subClientStore';
import AuthStore from './authStore';
import LeaveRequestStore from './leaveRequestStore';
import OvertimeStore from './overtimeStore';
import UndertimeStore from "./undertimeStore";
import DailyReportStore from "./dailyReportStore";
import WorkSpaceTypeStore from "./workSpaceTypeStore";
import WorkSpaceStore from "./workSpaceStore";
import WorkSpaceUserStore from "./workSpaceUserStore";
import BoardStore from "./boardStore";
import TimeRecordStore from "./timeRecordStore";
import CardGroupStore from "./cardGroupStore";
import CardStore from "./cardStore";
import CardCommentStore from "./cardCommentStore";
import ChatStore from "./chatStore";
import MessageStore from "./messageStore";
import ConversationStore from "./conversationStore";
import CardCheckListStore from "./cardChecklistStore";
import CardAttachmentStore from "./cardAttachmentStore";
import CommentAttachmentStore from "./commentAttachmentStore";
import PopoverStore from "./popoverStore";
import CardMemberStore from "./cardMemberStore";
import ChatAttachmentStore from "./chatAttachmentStore";
import PostStore from "./postStore";
import DateModalStore from "./dateModalStore";
import MediaStore from "./mediaStore";
import CardDateStore from "./cardDateStore";
import NotificationModalStore from "./notificationModalStore";

interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    dateModalStore: DateModalStore;
    notificationModalStore: NotificationModalStore;
    employeeStore: EmployeeStore;
    subClientStore: SubClientStore;
    authStore: AuthStore;
    leaveRequestStore: LeaveRequestStore;
    overtimeStore: OvertimeStore;
    undertimeStore: UndertimeStore;
    dailyReportStore: DailyReportStore;
    workSpaceTypeStore: WorkSpaceTypeStore;
    workSpaceStore: WorkSpaceStore;
    workSpaceUserStore: WorkSpaceUserStore;
    boardStore: BoardStore;
    timeRecordStore: TimeRecordStore;
    cardGroupStore: CardGroupStore;
    cardStore: CardStore;
    cardDateStore: CardDateStore;
    chatStore: ChatStore;
    messageStore: MessageStore;
    conversationStore: ConversationStore;
    cardCommentStore: CardCommentStore;
    cardChecklistStore: CardCheckListStore;
    cardAttachmentStore: CardAttachmentStore;
    commentAttachmentStore: CommentAttachmentStore;
    popOverStore: PopoverStore;
    chatAttachmentStore: ChatAttachmentStore;
    cardMemberStore: CardMemberStore;
    postStore: PostStore;
    mediaStore: MediaStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    dateModalStore: new DateModalStore(),
    notificationModalStore: new NotificationModalStore(),
    employeeStore: new EmployeeStore(),
    subClientStore: new SubClientStore(),
    authStore: new AuthStore(),
    leaveRequestStore: new LeaveRequestStore(),
    overtimeStore: new OvertimeStore(),
    undertimeStore: new UndertimeStore(),
    dailyReportStore: new DailyReportStore(),
    workSpaceTypeStore: new WorkSpaceTypeStore(),
    workSpaceStore: new WorkSpaceStore(),
    workSpaceUserStore: new WorkSpaceUserStore(),
    boardStore: new BoardStore(),
    timeRecordStore: new TimeRecordStore(),
    cardGroupStore: new CardGroupStore(),
    cardStore: new CardStore(),
    cardDateStore: new CardDateStore(),
    chatStore: new ChatStore(),
    messageStore: new MessageStore(),
    conversationStore: new ConversationStore(),
    cardCommentStore: new CardCommentStore(),
    cardChecklistStore: new CardCheckListStore(),
    cardAttachmentStore: new CardAttachmentStore(),
    commentAttachmentStore: new CommentAttachmentStore(),
    popOverStore: new PopoverStore(),
    cardMemberStore: new CardMemberStore(),
    chatAttachmentStore: new ChatAttachmentStore(),
    postStore: new PostStore(),
    mediaStore: new MediaStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}