import { observer } from 'mobx-react-lite';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import PaginatedTable from '../../app/common/table/PaginatedTable';
import DateHelper from '../../app/helpers/dateHelper';
import { FilterValues } from '../../app/models/common/filterValues';
import { Option } from '../../app/models/option';
import { TimeRecord, TimeRecordFilterValues } from '../../app/models/timeRecord';
import { useStore } from '../../app/stores/store';

const dateHelper = new DateHelper();

const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledContainer = styled.div`
        padding: 1rem 3rem 5rem 3rem;
`;

const DtrSummaryList = () => {
    const { timeRecordStore, commonStore } = useStore();
    const { filterTimeRecords, isTimeRecordsLoading, timeRecords, timeRecordsTotal } = timeRecordStore;
    const { getDepartments, loadDepartments, departments } = commonStore;

    const [filterValues, setFilterValues] = useState<TimeRecordFilterValues>({
        pageSize: 20,
        pageNumber: 1,
        searchKeyword: "",
        startDate:null,
        endDate: null,
        department:''
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    const handleSearch = (searchText: string) => {
        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText });
        }
    }

    const handleDateRangeFilter = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            if (filterValues.startDate != startDate && filterValues.endDate != endDate) {
                setFilterValues({ ...filterValues, pageNumber: 1, startDate: startDate, endDate: endDate });
            }
            else
                setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        } else if (startDate == null && endDate == null) {
            setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        }
    }

    const onChangeDepartmentFilter = (value: any) => {
        setFilterValues({ ...filterValues, department: value })
    }

    useEffect(() => {
        if (departments.length < 1)
            loadDepartments();
        filterTimeRecords(filterValues);
    }, [departments, filterValues, filterTimeRecords])

    return (
        <StyledContainer>
            <StyledHeader>
                Daily Time Record Summary
            </StyledHeader>
            <PaginatedTable<TimeRecord>
                columns={[
                    {
                        title: '#', field: 'orderNumber',
                        cellStyle: {
                            width: 50,
                            maxWidth: 50
                        },
                        headerStyle: {
                            width: 50,
                            maxWidth: 50
                        },
                    },
                    {
                        title: 'Employee Name', field: 'user.name',
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Time In', field: 'timeInDate',
                        render: (data: TimeRecord) => {
                            if (data.timeInDate)
                                return dateHelper.formatLocalShortMonthDateTime(data.timeInDate.toString());
                            return '';
                        },
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Time Out', field: 'timeOutDate',
                        render: (data: TimeRecord) => {
                            if (data.timeOutDate)
                                return dateHelper.formatLocalShortMonthDateTime(data.timeOutDate.toString());
                            return '';
                        },
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Total Time', field: 'user.name',
                        render: (data: TimeRecord) => {
                            if (data.timeInDate && data.timeOutDate) {
                                let timeIn = moment(data.timeInDate);
                                let timeOut = moment(data.timeOutDate);
                                let difference = timeOut.diff(timeIn);
                                let duration = moment.duration(difference);
                                let hourDuration = Math.floor(duration.asHours());
                                let minSecDuration = moment.utc(difference).format('mm:ss');

                                return `${hourDuration}:${minSecDuration}`;
                            }
                            return '';
                        },
                        cellStyle: {
                            width: 250,
                            minWidth: 250
                        },
                        headerStyle: {
                            width: 250,
                            minWidth: 250
                        },
                    },
                    {
                        title: 'Reason', field: '',
                        cellStyle: {
                            width: 300,
                            minWidth: 300
                        },
                        headerStyle: {
                            width: 300,
                            minWidth: 300
                        },                    },
                    { title: 'Department', field: 'user.department.name' },
                ]}
                isLoading={isTimeRecordsLoading}
                data={timeRecords!}
                total={timeRecordsTotal}
                onChangePage={handleOnChangePage}
                pageSize={filterValues.pageSize}
                pageNumber={filterValues.pageNumber}
                onSearchChange={handleSearch}
                toolbar={true}
                search={true}
                sorting={false}
                draggable={false}
                searchLabel='Employee Name'
                dateFilterLabel='Filter by Date'
                onDateRangeFilter={handleDateRangeFilter}
                dateRangeValues={[filterValues.startDate!, filterValues.endDate!]}
                dateRangeFilter={true}
            />
        </StyledContainer>
    );
}

export default observer(DtrSummaryList);