import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FaRegEye } from 'react-icons/fa';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PaginatedTable from '../../app/common/table/PaginatedTable';
import { TextOverflow } from '../../app/common/text/TextOverflow';
import DateHelper from '../../app/helpers/dateHelper';
import { FilterValues } from '../../app/models/common/filterValues';
import { DailyReport, DailyReportFilterValues } from '../../app/models/employeeDailyReport';
import { useStore } from '../../app/stores/store';
import DailyReportDetails from './DailyReportDetails';

const dateHelper = new DateHelper();
const swal = withReactContent(Swal);

//#region Styling
const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

//#endregion

const DailyReportRequestList = () => {
    const { dailyReportStore, modalStore } = useStore();
    const { filterDailyReports, isDailyReportListLoading, dailyReports,
        dailyReportsTotal, loadUserEmployees, isLoadingUserEmployees, userOptionValues, userEmployees } = dailyReportStore;

    const [filterValues, setFilterValues] = useState<DailyReportFilterValues>({
        pageSize: 20,
        pageNumber: 1,
        searchKeyword: "",
        userIds: []
    });

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }

    const handleDateRangeFilter = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            if (filterValues.startDate != startDate && filterValues.endDate != endDate) {
                setFilterValues({ ...filterValues, pageNumber: 1, startDate: startDate, endDate: endDate });
            }
            else
                setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        } else if (startDate == null && endDate == null) {
            setFilterValues({ ...filterValues, startDate: startDate, endDate: endDate });
        }
    }

    const handleUserFilter = (userIds: string[]) => {
        const currentUserIds = userIds.slice().sort();
        const hasNotChangeUserIds = filterValues.userIds.length === userIds.length && filterValues.userIds.slice().sort().every(function (value, index) {
            return value === currentUserIds[index];
        });

        if (hasNotChangeUserIds)
            setFilterValues({ ...filterValues, userIds: userIds })
        else
            setFilterValues({ ...filterValues, pageNumber:1, userIds: userIds })

    }

    //THIS WILL RUN ON LOAD OF COMPONENTS
    useEffect(() => {
        loadUserEmployees('');
        return () => {
            dailyReportStore.reset(); //CLEAN UP AFTER UNMOUNT 
        }
    }, []);

    useEffect(() => {
        filterDailyReports(filterValues);
    }, [filterValues])

    return (
        <StyledContainer>
            <StyledHeader>
                Daily Reports
            </StyledHeader>
            <PaginatedTable<DailyReport>
                columns={[
                    { title: 'Employee', field: 'user.name' },
                    { title: 'Date', render: (data: DailyReport) => dateHelper.customFormatLocalDate(data.timeRecord.timeOutDate.toString(), 'MMMM D, YYYY (ddd)') },
                    {
                        title: 'Summary',
                        render: (data: DailyReport) => (
                            <TextOverflow style={{ width: '300px' }}>{data.summary}</TextOverflow>
                        )
                    }
                ]}
                isLoading={isDailyReportListLoading}
                data={dailyReports!}
                total={dailyReportsTotal}
                onChangePage={handleOnChangePage}
                pageSize={20}
                pageNumber={filterValues.pageNumber}
                onSearchChange={(searchText: string) => {
                    setFilterValues({ ...filterValues, searchKeyword: searchText });
                }}
                toolbar={true}
                search={false}
                sorting={false}
                actions={[
                    rowData => ({
                        icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                        tooltip: 'View Daily Report',
                        onClick: e => modalStore.openModal(<DailyReportDetails dailyReport={rowData} />)
                    }),
                ]}
                onDateRangeFilter={handleDateRangeFilter}
                dateRangeValues={[filterValues.startDate!, filterValues.endDate!]}
                dateRangeFilter={true}
                dateFilterLabel='Filter by Date'
                onUserFilter={handleUserFilter}
                userFilterLabel='Filter by Employee'
                userValues={userOptionValues}
                userFilter={true}
                userFilterPlaceholder='Select Employee'
            />
        </StyledContainer>
    );
};

export default observer(DailyReportRequestList);