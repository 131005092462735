import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Header } from "semantic-ui-react";
import styled from "styled-components";
import AvatarImage from '../../../app/common/image/AvatarImage';
import PaginatedTable from "../../../app/common/table/PaginatedTable";
import { Employee, EmployeeFilterValues } from "../../../app/models/employee";
import { useStore } from '../../../app/stores/store';
import { history } from '../../../index';
import { FaRegEye } from 'react-icons/fa';
import { FilterValues } from '../../../app/models/common/filterValues';

const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

const EmployeeList = () => {
    const { employeeStore } = useStore();
    const { total, employees, userOptionValues, isLoading, loadEmployees, loadEmployeesListByDepartment, loadAllDepartment } = employeeStore;

    const [filterValues, setFilterValues] = useState<FilterValues>({
        pageSize: 10,
        pageNumber: 1,
        searchKeyword: "",
    });
    const [filterByDepartmentValues, setByDepartmentFilterValues] = useState<EmployeeFilterValues>({
        pageSize: 10,
        pageNumber: 1,
        searchKeyword: "",
        departments: []
    });

    const handleOnChangePage = (page: number) => {
        setByDepartmentFilterValues({ ...filterByDepartmentValues, departments: filterByDepartmentValues.departments, pageNumber: page })
    }

    const handleSearch = (searchText: string) => {
        const currentDepartments = filterByDepartmentValues.departments.slice().sort();
        if (filterValues.searchKeyword != searchText) {
            setByDepartmentFilterValues({ ...filterByDepartmentValues, pageNumber: 1, departments: filterByDepartmentValues.departments, searchKeyword: searchText })
        } else {
            setByDepartmentFilterValues({ ...filterByDepartmentValues, departments: currentDepartments, searchKeyword: searchText })
        }
    }
    const handleUserFilter = (departments: string[]) => {
        const currentDepartments = departments.slice().sort();
        const hasNotChangeUserIds = filterByDepartmentValues.departments.length === departments.length && filterByDepartmentValues.departments.slice().sort().every(function (value, index) {
            return value === currentDepartments[index];
        });
        if (hasNotChangeUserIds)
            setByDepartmentFilterValues({ ...filterByDepartmentValues, departments: currentDepartments })
        else
            setByDepartmentFilterValues({ ...filterByDepartmentValues, pageNumber: 1, departments: departments })

    }
    useEffect(() => {
        loadEmployees(filterValues);
        loadAllDepartment();
    }, [])

    useEffect(() => {
        loadEmployeesListByDepartment(filterByDepartmentValues);
    }, [filterByDepartmentValues, loadEmployeesListByDepartment])


    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    Employees
                </StyledHeader>
                <PaginatedTable<Employee>
                    columns={[
                        {
                            title: 'Employee Name', field: 'name',
                            render: (data: Employee) => {
                                return (
                                    <>
                                        <AvatarImage userId={data.id} hasPhoto={data.hasProfilePicture} circular style={{ marginRight: '1em' }} />
                                        <span>{data.name}</span>
                                    </>
                                );
                            }
                        },
                        { title: 'Department', field: 'job.department.name' },
                        { title: 'Job Title', field: 'job.jobTitle.name' },
                    ]}
                    actions={[rowData => ({
                        icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                        tooltip: 'View Employee',
                        onClick: e => history.push(`/employees/${rowData.id}`),

                    })]}
                    total={total}
                    pageSize={filterByDepartmentValues.pageSize}
                    pageNumber={filterByDepartmentValues.pageNumber}
                    totalCount={total ?? 0}
                    sorting={false}
                    draggable={false}
                    onChangePage={handleOnChangePage}
                    onSearchChange={handleSearch}
                    data={employees}
                    isLoading={isLoading}
                    toolbar={true}
                    search={true}
                    userFilter={true}
                    userFilterPlaceholder='Filter by Department'
                    userValues={userOptionValues}
                    onUserFilter={handleUserFilter}
                />
            </StyledContainer>
        </>
    )
}

export default observer(EmployeeList);