import moment from "moment";

export default class DateHelper {
    formatLocalDate = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('dddd, MMMM DD, YYYY')
        }

        return output;
    }

    customFormatLocalDate = (date: string, format: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format(format)
        }

        return output;
    }

    formatShortLocalDate = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MM/DD/YYYY')
        }

        return output;
    };

    formatLocalDateTime = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MM/DD/YYYY hh:mm A')
        }

        return output;
    };

    formatLocalShortMonthDateTime = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MMM DD YYYY (ddd) hh:mm A')
        }

        return output;
    };

    formatLocalShortMonthDay = (date: string) => {
        let output = '';

        if (date) {
            let utcDate = moment.utc(date);
            let localDate = utcDate.local();

            output = localDate.format('MMMM DD, YYYY (ddd)')
        }

        return output;
    };

    formatShortDate = (date: string) => {
        let output = '';

        if (date)
            output = moment(date).format('MM/DD/YYYY');

        return output;
    };

    formatMonthYear = (date: string) => {
        let output = '';

        if (date)
            output = moment(date).format('MMMM yyyy');

        return output;
    };

    serializeUtc = (utc: string) => {
        return moment.utc(utc).format();
    };

    // Changes the date's timezone to UTC 
    // while retaining the original date
    convertToUtcDate = (date: string) => {
        let dateString = this.formatLocalDate(date);

        return this.serializeUtc(dateString);
    };

    getLocalTime = (utcTime:string) => {
        if (!utcTime)
            return '';

        let date = new Date(`1/1/2000 ${utcTime} UTC`);

        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    toLocalTimeString = (param) => {
        let date = new Date(param);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
   toReadableDate = (date) => {
        let today = new Date();
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let formatted_date = months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
        return formatted_date;



    }

    getDateOnly = (date: Date | null | undefined) => {
        return date ? moment(date).format("YYYY-MM-DD"): '';
    }
}