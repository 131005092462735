import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import agent from '../api/agents';
import { FilterValues } from '../models/common/filterValues';
import { WorkSpace, WorkSpaceFormValues } from '../models/workSpace';
import { history } from '../../index';

export default class WorkSpaceStore {
    workSpaces: WorkSpace[] = [];
    workSpacesTotal: number = 0;
    isWorkSpaceListLoading: boolean = false;
    isLoading: boolean = false;
    activeWorkspace: WorkSpace | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    filterWorkSpaces = async (filters: FilterValues) => {
        this.isWorkSpaceListLoading = true;

        try {
            const { total, data } = await agent.WorkSpaces.filter(filters);
            runInAction(() => {
                this.workSpaces = data;
                this.workSpacesTotal = total;
                this.isWorkSpaceListLoading = false;
             

            });
        } catch (e) {
            console.log(e);
            this.isWorkSpaceListLoading = false;
        }
    }

    loadWorkSpace = async (id: string) => {
        this.isLoading = true;

        try {
            let workSpace = await agent.WorkSpaces.get(id);
            this.isLoading = false;
            this.activeWorkspace = workSpace;
            return workSpace;
        } catch (e) {
            throw e;
        }
    }

    add = async (workSpace: WorkSpaceFormValues) => {
        try {
            const workSpaceId = await agent.WorkSpaces.add(workSpace);
            runInAction(() => {
                if (workSpaceId) {
                    toast.success('Workspace was created successfully.');
                    //history.push({
                    //    pathname: '/workspaces/' + workSpaceId,
                    //    state: {
                    //        workSpaceId: workSpaceId
                    //    }
                    //});
                    history.goBack();
                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    update = async (workSpace: WorkSpaceFormValues) => {
        try {
            const workSpaceId = await agent.WorkSpaces.update(workSpace);
            runInAction(() => {
                if (workSpaceId) {
                    toast.success('Workspace was updated successfully.');
                    history.goBack();
                    return true;
                }
                return false;
            });
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    deleteWorkSpace = async (id: string, filters: FilterValues) => {
        try {
            await agent.WorkSpaces.delete(id);
            this.filterWorkSpaces(filters);
            runInAction(() => {
                toast.success('Workspace was deleted successfully.');
            });
        } catch (e) {
            console.log(e);
        }
    }
}