import { TableFooter } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FaPencilAlt, FaRegEye, FaTrashAlt } from 'react-icons/fa';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Grid, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PaginatedTable from '../../../app/common/table/PaginatedTable';
import { Board } from '../../../app/models/board';
import { FilterValues } from '../../../app/models/common/filterValues';
import { useStore } from '../../../app/stores/store';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
const swal = withReactContent(Swal);
const StyledHeader = styled(Header)`margin-bottom: 20px !important;`;

const StyledColumn = styled(Grid.Column)`
        display: flex !important;
        align-items: flex-end !important;
        justify-content: flex-end !important;
`;

const StyledContainer = styled.div`
        padding: ${props => props.theme.padding.featureContainer};
`;

const BackButton = styled.div`
       color: rgb(0 0 0 / 87%);
        :hover {
             color: rgb(0 0 0 / 87%);
        }
`;
const tableTheme = createMuiTheme({
    overrides: {
        MuiTableRow: {
            hover: {
                "&:hover": {
                    backgroundColor: " #e0effd !important"
                }
            }
        }
    }
});
const BoardList = () => {
    const { boardStore } = useStore();
    const { filterBoards, isBoardListLoading, boards,
        boardsTotal, deleteBoard } = boardStore;
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    const [filterValues, setFilterValues] = useState<FilterValues>({
        id:id,
        pageSize: 10,
        pageNumber: 1,
        searchKeyword: ""
    });

    const handleSearch = (searchText: string) => {
        if (filterValues.searchKeyword != searchText) {
            setFilterValues({ ...filterValues, pageNumber: 1, searchKeyword: searchText });
        } else {
            setFilterValues({ ...filterValues, searchKeyword: searchText });
        }
    }

    const handleOnChangePage = (page: number) => {
        setFilterValues({ ...filterValues, pageNumber: page })
    }
    const handleOnRowClick = (event, rowData) => {
        history.push(`/workspaces/projects/${rowData.id}`)
    }
    useEffect(() => {
        filterBoards(filterValues);
    }, [filterValues, filterBoards])

    return (
        <>
            <StyledContainer>
                <StyledHeader>
                    <Grid>
                        <Grid.Column width={8}>
                            <BackButton as={Link} to={{
                                pathname: '/workspaces',
                            }}>
                                <i className="left chevron icon" style={{ marginLeft: '10px', marginRight: '10px', cursor: 'pointer' }} title='Back'></i>
                            </BackButton>
                            Projects
                            
                        </Grid.Column>
                        <StyledColumn width={8}>
                            <Button icon='add' color='blue' as={Link} to={{
                                pathname: `/workspaces/projects/add`,
                                state: { workSpaceId: id }
                            }} content='Add a New Project' />
                        </StyledColumn>
                    </Grid>
                </StyledHeader>
                <ThemeProvider theme={tableTheme}>
                    <PaginatedTable<Board>
                        columns={[
                            { title: 'Project Name', field: 'name' },
                        ]}
                        onRowClick={handleOnRowClick}
                        isLoading={isBoardListLoading}
                        data={boards!}
                        total={boardsTotal}
                        onChangePage={handleOnChangePage}
                        pageSize={filterValues.pageSize!}
                        pageNumber={filterValues.pageNumber}
                        onSearchChange={handleSearch}
                        toolbar={true}
                        search={true}
                        sorting={false}
                        draggable={false}
                        actions={[
                            //rowData => ({
                            //    icon: () => <FaRegEye style={{ color: '#97a4b5', fontSize: '18px' }} />,
                            //    tooltip: 'View',
                            //    onClick: e => {
                            //        history.push(`/projects/${rowData.id}`)
                            //    },
                            //}),
                            rowData => ({
                                icon: () => <FaPencilAlt style={{ color: '#97a4b5', fontSize: '16px' }} />,
                                tooltip: 'Edit',
                                onClick: e => {
                                    history.push(`/workspaces/projects-edit/${rowData.id}`)
                                },
                            }),
                            rowData => ({
                                icon: () => <FaTrashAlt style={{ color: '#97a4b5', fontSize: '16px' }} />,
                                tooltip: 'Delete',
                                onClick: e => {
                                    swal.fire({
                                        title: 'Delete?',
                                        text: "Are you sure you want to delete this Project?",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#3085d6',
                                        cancelButtonColor: '#d33',
                                        cancelButtonText: 'No',
                                        confirmButtonText: 'Yes'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            deleteBoard(rowData.id, filterValues);
                                        }
                                    });
                                },
                            }),
                        ]}
                    />
                </ThemeProvider>
              
              
              
            </StyledContainer>
        </>
    );
};

export default observer(BoardList);