import { Avatar, Conversation, EllipsisButton, ConversationList, Search } from '@chatscope/chat-ui-kit-react';
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { BiMessageAdd } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { RiGroupLine } from "react-icons/ri";
import { Dropdown, Header, Icon } from "semantic-ui-react";
import styled from 'styled-components';
import { Conversation as ConversationModel } from '../../models/conversation';
import { useStore } from "../../stores/store";
import AvatarImage from "../image/AvatarImage";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const swal = withReactContent(Swal)
// #region Styles

const ConversationContainer = styled.div<{ isShow: boolean }>`
    && {
        display: ${p => (p.isShow ? 'block' : 'none')};
        overflow-y: auto;
        position: fixed;
        right: 15px;
        top: 68px;
        background-color: #fff;
        max-height: 800px;
        min-height:  402px;
        box-shadow: 0 0 5px #b2b2b2;
        width: 350px;
        border-radius: 6px;
        padding: 0px 15px;
    }
`;

const ConversationHeader = styled.div`
    && {
        height: 30px;
        padding: 5px;
        margin: 10px 0;
    }
`;

const NoMessagesPlaceholder = styled(Header)`
    &&{
        opacity: .45;
        margin-top: 5px;
    }
`;

const Placeholder = styled.div`
    && {
      display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }
`;

const SettingIcon = styled(FiSettings)`
    && {
        margin: 0 0 0 7px;
        float: right;
        height: 23px;
        width: 23px;
        cursor: pointer;
        color: #5b5a5a;
        &:hover{
            color: #2a2a2a;
        }
    }
`;
const GroupMessageIcon = styled(RiGroupLine)`
    && {
        margin: 0 5px 0 5px;
        float: right;
        height: 21px;
        width: 21px;
        cursor: pointer;
        color: #5b5a5a;
        &:hover{
            color: #2a2a2a;
        }
    }
`;
const NewMessageIcon = styled(BiMessageAdd)`
    && {
        margin: 0 7px;
        float: right;
        height: 23px;
        width: 23px;
        cursor: pointer;
        color: #5b5a5a;
        &:hover{
            color: #2a2a2a;
        }
    }
`;

// #endregion

function useOuterClick(callback: any) {
    const callbackRef = useRef<any>(); // initialize mutable ref, which stores callback
    const innerRef = useRef<any>(); // returned to client, who marks "border" element

    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });

    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);

        function handleClick(ev: MouseEvent | TouchEvent) {
            if (innerRef.current && callbackRef.current &&
                !innerRef!.current!.contains!(ev.target)
            ) callbackRef!.current!(ev);

            return false;
        }
    }, []); // no dependencies -> stable click listener

    return innerRef; // convenience for client (doesn't need to init ref himself) 
}

function CoversationList() {
    const { chatStore, conversationStore, messageStore, userStore } = useStore();
    const { showMessageBox, hideMessageBox, setNewMessageOpen, isChatShow, hideChatBox, setGroupNewMessageOpen, deleteMember } = chatStore;
    const { loadMoreConversation, conversations, setSelectedConversation, setSearchQuery, getSearchQuery, searchQuery, selectedConversation } = conversationStore;
    const { loadMessage, updateIsRead, setIsMinimize, setFiles, setMessages } = messageStore;
    const { user } = userStore;

    useEffect(() => {
        loadMoreConversation({ pageNumber: 0, pageSize: 0, searchKeyword: "" });
    }, [loadMoreConversation])

    const handleConversationClick = async (conversation: ConversationModel) => {
     
        if ((conversation.readReceiptUserIds && conversation.readReceiptUserIds.indexOf(userStore.user!.id) == -1) &&
            (conversation.recentMessage && conversation.recentMessage.senderId != userStore.user!.id)) {
            await updateIsRead(conversation.id, userStore.user!.id);
        }

        await loadMessage(conversation.id);
        hideChatBox();
        setSelectedConversation(conversation);
        setNewMessageOpen(false);
        setIsMinimize(false);
        setFiles([]);
        showMessageBox();

    
    };
    const handleEllipsisClick = (conversationId : string) => {
        swal.fire({
            title: 'Delete Conversation?',
            text: "Are you sure you want to delete this conversation?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteMember(conversationId, user?.id!).then(
                    () => {
                        loadMoreConversation({ pageNumber: 0, pageSize: 0, searchKeyword: '' });
                        if (conversationId == selectedConversation?.id) {
                            setMessages([]);
                            hideMessageBox();
                        }
                    })

            }
        });
    }

    const handleConversationSearch = (value: string) => {
        setSearchQuery(value ? value : '');
        let searchQuery = getSearchQuery();
        loadMoreConversation({ pageNumber: 0, pageSize: 0, searchKeyword: searchQuery });
    }

    const openNewMessage = () => {
        hideAllBoxes();
        setNewMessageOpen(true);
    }

    const openNewGroupMessage = () => {
        hideAllBoxes();
        setGroupNewMessageOpen(true);
    }

    const hideAllBoxes = () => {
        setNewMessageOpen(false);
        setGroupNewMessageOpen(false);
        hideChatBox();
        hideMessageBox();
        hideChatBox();
    }

    const innerRef = useOuterClick(
        (ev: any) => {
            if (!ev.target.classList.contains('chat-box-button') && !ev.target.classList.contains('swal2-styled')) {
                hideChatBox();
                if (searchQuery != '') {
                    setSearchQuery('');
                    handleConversationSearch('');
                }
            }
        }
    );

    useEffect(() => {

    }, [])

    return (
        <ConversationContainer ref={innerRef} isShow={isChatShow}>
            <ConversationHeader>
                <Dropdown
                    icon={null}
                    trigger={<SettingIcon title='Chat Settings' />}
                    className='small'
                    pointing='top right'
                    style={{ margin: '0 5px', float: 'right', height: 23, width: 24, cursor: "pointer" }}>
                    <Dropdown.Menu>
                        <Dropdown.Item text='Online' />
                        <Dropdown.Item text='Offline' />
                        <Dropdown.Item text='Busy' />
                        <Dropdown.Item text='Away' />
                    </Dropdown.Menu>
                </Dropdown>
                <GroupMessageIcon onClick={openNewGroupMessage} title='New Group Chat' />
                <NewMessageIcon onClick={openNewMessage} title='New Chat' />
                {/*<AiOutlineUserAdd style={{ marginLeft: 5,float: 'right', height: 23, width: 24, cursor: "pointer" }} />*/}
            </ConversationHeader>
            <ConversationList>
                <Search
                    value={searchQuery}
                    onChange={handleConversationSearch}
                    placeholder="Search..."
                    onClearClick={handleConversationSearch}
                    style={{
                        marginBottom: '10px',
                        height: '36px',
                        fontSize: '14px'
                    }}
                />
                {conversations.length ? conversations.map(conversation => {
                    const isUnread = (conversation.readReceiptUserIds && conversation.readReceiptUserIds.indexOf(userStore.user!.id) == -1) &&
                        (conversation.recentMessage && conversation.recentMessage.senderId != userStore.user?.id);

                    let cleanedContent = 'file';

                    if (conversation.recentMessage.content) {
                        cleanedContent = conversation.recentMessage ?
                            conversation.recentMessage.content
                                .replace(/&nbsp;|<br>/g, ' ')
                                .replace('[[SystemGenerated]]', '')
                                .trim() :
                            "No Message";
                    }

                 

                    const lastSenderName = conversation.recentMessage && conversation.type === "Group" ?
                        conversation.recentMessage.content?.includes('[[SystemGenerated]]') ? null : conversation.recentMessage.sender
                        :
                        conversation.recentMessage.senderId === userStore.user!.id ? conversation.recentMessage.sender : null;

                    return (
                        <Conversation key={conversation.id}
                            style={{
                                fontWeight: isUnread ? 'bold' : '',
                                paddingLeft: '0px',
                                paddingRight: '0px',
                                fontSize:'14px'
                            }}
                        >
                            <Conversation.Content onClick={() => handleConversationClick(conversation)}  lastSenderName={lastSenderName} name={conversation.name} info={cleanedContent} unreadDot={isUnread} />  
                         
                            <Avatar name="Joe" size="md" onClick={() => handleConversationClick(conversation)}>
                                <AvatarImage userId={conversation.conversationPicture} hasPhoto={conversation.hasConversationPicture}
                                    circular style={{ marginRight: '1em', width: 40, height: "auto" }} />
                            </Avatar>
                            <Conversation.Operations visible>
                                {conversation.type !== "Group" && <EllipsisButton style={{ color: 'black' }} onClick={() => handleEllipsisClick(conversation.id)} />}
                            </Conversation.Operations>
                        </Conversation>
                    );
                }) :
                    (
                        <Placeholder>
                            <Icon name='envelope outline' size='huge' disabled />
                            <NoMessagesPlaceholder as='h4'>No Messages</NoMessagesPlaceholder>
                        </Placeholder>
                    )
                }
            </ConversationList>
        </ConversationContainer>
    )
}

export default observer(CoversationList);