import { makeAutoObservable } from "mobx";
import agent from "../api/agents";
import { Media } from "../models/common/media";

export default class mediaStore {
    media: Media[] = [];
    constructor() {
        makeAutoObservable(this);
    }

    getPostAttachment = async (id: string) => {
        let media = this.getById(id);

        if (!media) {
            this.media = [...this.media, {
                id: id,
                url: '',
            }]

            let blob = await agent.PostAttachments.get(id);

            media = this.getById(id);
            if (media) {
                var urlCreator = window.URL || window.webkitURL;
                var url = urlCreator.createObjectURL(blob);
                media.url = url;
                media.type = blob.type;
            };
           
        }

        return this.getById(id);
    }

    getProfilePicture = async (id: string) => {
        try {
            let media = this.getById(id);

            if (!media) {
                let blob = await agent.Account.getProfilePhoto(id, {
                    responseType: 'blob'
                });

                var urlCreator = window.URL || window.webkitURL;
                var url = urlCreator.createObjectURL(blob);

                media = {
                    id: id,
                    url
                }

                this.media.push(media)
            }

            return media
        } catch (e) {
            console.log(e);
        }
    }

    private getById = (id: string) => {
        return this.media.find(m => m.id === id);
    }
}